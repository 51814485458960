import React from 'react';
import { DoughnutDataItem, DoughnutTile } from 'components';
import styles from '../styles.module.scss';
import { formatNumber } from 'shared/utils';
import { ReportStatus } from 'shared/api/client';

interface ClusterReportingStatusProps {
  showSkeleton?: boolean;
  total: number;
  reportsReceived: number;
  reportsMissing: number;
  reportsPartial: number;
  onClick?: (key: ReportStatus) => void;
}

export const ClusterReportingStatus = React.memo(
  ({
    showSkeleton,
    total,
    reportsReceived,
    reportsMissing,
    onClick,
    reportsPartial,
  }: ClusterReportingStatusProps) => {
    const reportsReceivedPrecent = (reportsReceived / total) * 100 || 0;
    const reportsMissingPrecent = (reportsMissing / total) * 100 || 0;
    const reportsPartialPrecent = (reportsPartial / total) * 100 || 0;

    const onClickItemHandler = (key: ReportStatus) => () => {
      if (typeof onClick === 'function') {
        onClick(key);
      }
    };

    const items: DoughnutDataItem[] = [
      {
        key: 'partialReceived',
        value: reportsReceivedPrecent,
        color: '#57AE60',
        onClick: onClickItemHandler('DONE'),
        legend: {
          label: <span className={styles.legendLabel}>Reports received</span>,
          value: `${formatNumber(reportsReceivedPrecent)}%`,
        },
      },
      {
        key: 'partialMissing',
        value: reportsMissingPrecent,
        color: '#F2994A',
        onClick: onClickItemHandler('MISSING'),
        legend: {
          label: <span className={styles.legendLabel}>Reports missing</span>,
          value: `${formatNumber(reportsMissingPrecent)}%`,
        },
      },
      {
        key: 'partialReports',
        value: reportsPartialPrecent,
        onClick: onClickItemHandler('ERROR'),
        color: '#E84F40',
        legend: {
          label: <span className={styles.legendLabel}>Partial reports</span>,
          value: `${formatNumber(reportsPartialPrecent)}%`,
        },
      },
    ];

    return (
      <DoughnutTile
        className={styles.tile}
        showSkeleton={showSkeleton}
        items={items}
        classes={{ wrapper: styles.doughnutTileWrapper }}
        title="Cluster Reporting Status"
      />
    );
  },
);

ClusterReportingStatus.displayName = 'ClusterReportingStatus';
