import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

export const LoadingDots = () => {
  return (
    <span className={cx(styles.loadingDots)}>
      <span className={cx(styles.loadingDot)}>.</span>
      <span className={cx(styles.loadingDot)}>.</span>
      <span className={cx(styles.loadingDot)}>.</span>
    </span>
  );
};
