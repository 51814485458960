import React, { CSSProperties } from 'react';
import cx from 'classnames';
import styled from 'styled-components';

export interface IconProps {
  name: string;
  size?: CSSProperties['fontSize'];
  color?: CSSProperties['color'];
  style?: CSSProperties;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

const StyledIcon = styled.span`
  &::before {
    color: ${(props) => props?.color};
  }
`;

export const Icon = ({
  onClick,
  className,
  style,
  color,
  size,
  name,
}: IconProps) => {
  if (!name) return <></>;
  return (
    <StyledIcon
      color={color}
      data-testid="icon"
      onClick={onClick}
      style={{ fontSize: size, color, ...style }}
      className={cx('unskript-icon', `unskriptIcon-${name}`, className)}
    ></StyledIcon>
  );
};
