import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import { ColumnBodyOptions } from 'primereact/column';
import { ReportTableDataItem } from 'pages/reports/shared/models';

export const SkeletonTemplateReportTable = (
  _: ReportTableDataItem,
  options: ColumnBodyOptions,
) => {
  const isLongTemplates: { [key in keyof ReportTableDataItem]: boolean } = {
    object: true,
    check: true,
    namespace: true,
    explanation: true,
  };
  if (isLongTemplates[options?.field as keyof ReportTableDataItem]) {
    return (
      <div
        style={{
          display: 'flex',
          gap: '0.2rem',
          flexDirection: 'column',
        }}
      >
        <Skeleton height="0.72rem" />
        <Skeleton height="0.72rem" />
      </div>
    );
  }
  return <Skeleton height="0.72rem" />;
};
