import { ExecutionsService, ChecksService } from 'shared/api/client';
import { mapCheckResults } from '../shared/utils';
import { Params } from 'react-router-dom';
import { normalizeArray } from 'shared/utils';
import { ReportModel } from './models';

export function fetchData(params: Params<string>) {
  const { executionId } = params;

  if (executionId) {
    const request = ExecutionsService.getExecutionApiV1ExecutionsExecutionIdGet(
      {
        executionId: executionId,
      },
    );

    return Promise.all([
      request,
      ChecksService.listChecksApiV1ChecksGet(),
    ]).then(([executionResponse, listChecksResponse]) => {
      const checkList = normalizeArray({
        array: listChecksResponse.checks || [],
        key: 'id',
      });
      const mapped = mapCheckResults(
        executionResponse?.check_results || [],
        checkList,
      );

      return {
        id: executionResponse?.id,
        createdAt: executionResponse?.created_at,
        finishedAt: executionResponse?.finished_at,
        suiteId: executionResponse?.suite_id,
        status: executionResponse?.status,
        items: normalizeArray({ array: mapped, key: 'checkResultId' }),
      } as ReportModel;
    });
  }
  return Promise.reject(`The "id" parameter is missing`);
}
