import React, { useCallback, useEffect, useMemo } from 'react';
import { RoutePageData } from 'router/shared/models';
import { Icon, usePageContext } from 'components';
import { useCheckSummary } from './context';
import { getDashboard } from './shared/requests';
import { useParams } from 'react-router-dom';
import { ApiError, ReportStatus } from 'shared/api/client';
import { useLoader } from 'hooks';
import { getDashboardDataByClusters, getDashboardTiles } from './shared/utils';
import { DashboardCharts, DashboardView } from './dashboard-view';
import { CheckSummaryClusters } from 'pages/check-summary';
import { ErrorPage } from 'pages/error-page';
import { FailedStatusBy } from './dashboard-view/failed-status-by-severity.chart';
import { ClusterReportingStatus } from './dashboard-view/cluster-reporting-status.chart';
import { SummaryCluster, TileID } from './shared/models';
import { FilterMeta } from 'components/filter-tags';
import { Calendar } from 'primereact/calendar';
import styles from './styles.module.scss';
import { getDate } from 'components/utils';

interface StateProps {}

const Dashboard = () => {
  const { state, setState, error404 } = usePageContext<StateProps>();
  const paramsUrl = useParams();
  const { date: executionDate, clusters, setClusters } = useCheckSummary();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDataRequest = useCallback(
    getDashboard(getDate((executionDate || new Date())?.toJSON())),
    [paramsUrl, executionDate],
  );

  const fetchDataRequestErrorHandler = useCallback(
    (e: ApiError) => {
      if (e.status === 404) {
        setState((prev) => ({
          ...prev,
          isLoading: false,
        }));
        error404();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paramsUrl],
  );

  const [filters, setFilters] = React.useState<FilterMeta>({});

  const { loadedData, isPending } = useLoader(
    fetchDataRequest,
    fetchDataRequestErrorHandler,
  );

  useEffect(() => {
    if (loadedData && !isPending) {
      setClusters(loadedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedData, isPending]);

  const allData = React.useMemo(
    () => getDashboardDataByClusters(clusters || {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clusters],
  );
  const showSkeleton = Object.keys(clusters || {}).length === 0 && isPending;

  const onClickTileHandler = useCallback((tile: TileID) => {
    switch (tile) {
      case 'total': {
        setFilters({});
        break;
      }
      case 'healthy': {
        setFilters({ isHealthy: { value: [true] } });
        break;
      }
      case 'unHealthy': {
        setFilters({ isHealthy: { value: [false] } });
        break;
      }
      default: {
        break;
      }
    }
  }, []);

  const tiles = useMemo(
    () => getDashboardTiles({ ...allData, onClick: onClickTileHandler }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allData, onClickTileHandler],
  );

  const onClearHandler = (key: keyof SummaryCluster, value: string) => {
    setFilters((prev) => {
      const newState = { ...prev };
      delete newState[key];
      return newState;
    });
  };

  const onClickFailedStatusBySeverity = useCallback(
    (key: string, isSeverity: boolean) => {
      if (isSeverity) {
        setFilters({ severity: { value: [key] } });
      } else {
        setFilters({ priority: { value: [key] } });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onClickClusterReportingStatus = useCallback(
    (key: ReportStatus) => {
      setFilters({ reportStatus: { value: [key] } });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (state?.isError404) {
    return <ErrorPage />;
  }

  return (
    <DashboardView>
      <DashboardCharts
        tiles={tiles}
        doughnutsCharts={
          <>
            <ClusterReportingStatus
              showSkeleton={showSkeleton}
              total={allData?.totalClusters}
              reportsReceived={allData?.reportsReceived}
              reportsMissing={allData?.reportsMissing}
              reportsPartial={allData?.reportsPartial}
              onClick={onClickClusterReportingStatus}
            />
            {
              <FailedStatusBy
                onClick={onClickFailedStatusBySeverity}
                data={allData?.groupedData}
                showSkeleton={showSkeleton}
              />
            }
          </>
        }
        showSkeleton={showSkeleton}
      />
      <CheckSummaryClusters
        onClear={onClearHandler}
        filters={filters}
        clusters={clusters || {}}
      />
    </DashboardView>
  );
};

const HeaderRight = () => {
  const { date, setDate } = useCheckSummary();
  return (
    <div className={styles.date}>
      <div>
        <Calendar
          showIcon
          style={{ width: '9rem' }}
          inputClassName={styles.input}
          iconPos={'right'}
          icon={<Icon size={'1.5rem'} name="calendar" />}
          placeholder={'MM/DD/YYYY'}
          value={date}
          onChange={(e) => setDate(e?.value)}
        />
      </div>
    </div>
  );
};

export default {
  title: 'Check Summary',
  description: 'test',
  headerRightTemplate: <HeaderRight />,
  route: { Component: Dashboard, path: '/' },
  pageStyle: 'summary',
} as RoutePageData;
