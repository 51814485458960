import React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { APIOptions, PrimeReactProvider } from 'primereact/api';
import { DialogProvider } from 'shared/providers/dialog';
import { ToastProvider } from 'shared/providers/toast';

function App() {
  const value: Partial<APIOptions> = {
    inputStyle: 'filled',
    ripple: true,
  };

  return (
    <PrimeReactProvider value={value}>
      <ToastProvider>
        <DialogProvider>
          <RouterProvider
            router={router}
            future={{ v7_startTransition: true }}
          />
        </DialogProvider>
      </ToastProvider>
    </PrimeReactProvider>
  );
}

export default App;
