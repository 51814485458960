import { TileProps } from 'components/tile';
import { DataTableFilterMeta } from 'primereact/datatable';
import { ReportTableDataItem } from '../models';
import { defaultFilters } from './report-filter.configurations';

interface ReportTilesProps {
  data: ReportTableDataItem[];
  reportId?: string;
  setFilter: (
    reportId: string,
    data: (prev: DataTableFilterMeta) => DataTableFilterMeta,
  ) => void;
  onClickTileHandler?: () => void;
}

export const getReportTiles = ({
  data,
  reportId,
  setFilter,
  onClickTileHandler,
}: ReportTilesProps): TileProps[] => [
  {
    title: 'Total Checks',
    count: (data || []).length,
    icon: 'checks',
    onClick: () => {
      if (typeof onClickTileHandler === 'function') {
        onClickTileHandler();
      }
      if (reportId) {
        setFilter(reportId, (prev) => {
          const newState = { ...prev };
          return {
            ...newState,
            ...defaultFilters,
            result: { ...prev?.namespace, value: null },
          };
        });
      }
    },
  },
  {
    title: 'Passed',
    count: (data || []).filter((x) => x.status === 'PASS')?.length || 0,
    textColor: 'green',
    icon: 'pass',
    onClick: () => {
      if (typeof onClickTileHandler === 'function') {
        onClickTileHandler();
      }
      if (reportId) {
        setFilter(reportId, (prev) => {
          const newState = { ...prev };
          return {
            ...newState,
            ...defaultFilters,
            result: { ...prev?.namespace, value: ['Pass'] },
          };
        });
      }
    },
  },
  {
    title: 'Failed',
    count: (data || []).filter((x) => x.status === 'FAIL')?.length || 0,
    textColor: 'red',
    icon: 'fail',
    onClick: () => {
      if (typeof onClickTileHandler === 'function') {
        onClickTileHandler();
      }
      if (reportId) {
        setFilter(reportId, (prev) => {
          const newState = { ...prev };
          return {
            ...newState,
            ...defaultFilters,
            result: { ...prev?.namespace, value: ['Fail'] },
          };
        });
      }
    },
  },
  {
    title: 'No Result',
    count:
      (data || []).filter((x) => ['IGNORE', 'ERROR'].includes(x?.status || ''))
        ?.length || 0,
    textColor: 'orange',
    icon: 'error',
    onClick: () => {
      if (typeof onClickTileHandler === 'function') {
        onClickTileHandler();
      }
      if (reportId) {
        setFilter(reportId, (prev) => {
          const newState = { ...prev };
          return {
            ...newState,
            ...defaultFilters,
            result: { ...prev?.namespace, value: ['Ignore', 'Error'] },
          };
        });
      }
    },
  },
];
