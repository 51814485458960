import React from 'react';
import cx from 'classnames';
import { Header } from 'components/header';
import styles from './app.module.scss';
import { Outlet } from 'react-router-dom';
import { AuthProvider } from 'shared/providers/auth';
import { CheckSummaryProvider } from 'pages/dashboard/context/provider';
import { CurrentClusterProvider } from 'pages/check-summary/current-cluster/context/provider';
import { CurrentReportProvider } from 'pages/reports/current-report/context/provider';

export const Layout = ({ children }: React.PropsWithChildren) => {
  return (
    <div className={cx(styles.app, 'p-input-filled')}>
      <Header></Header>
      <div
        id={'mainScrollable'}
        style={{ marginTop: 64, height: '100%' }}
        className={cx(styles.appContainer, 'scrollable')}
      >
        {children}
      </div>
    </div>
  );
};

function Shell() {
  return (
    <AuthProvider>
      <CheckSummaryProvider>
        <CurrentClusterProvider>
          <CurrentReportProvider>
            <Layout>
              <Outlet />
            </Layout>
          </CurrentReportProvider>
        </CurrentClusterProvider>
      </CheckSummaryProvider>
    </AuthProvider>
  );
}

export default Shell;
