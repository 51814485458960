import React from 'react';
import { DoughnutDataItem, DoughnutTile } from 'components';
import styles from '../styles.module.scss';
import { GroupedDataKey } from '../shared/models';

import { getFailedStatusColorsBy, sortFailedStatus } from '../shared/utils';

export interface FailedStatusByProps {
  showSkeleton?: boolean;
  data: Record<GroupedDataKey, number>;
  onClick?: (key: string, isSeverity: boolean) => void;
}

interface ColorBoxProps {
  color: string;
}

const ColorBox = ({ color }: ColorBoxProps) => {
  return (
    <span
      style={{
        color,
        width: '0.75rem',
        height: '0.75rem',
        borderRadius: '0.25rem',
        background: color,
      }}
    />
  );
};

export const FailedStatusBy = React.memo(
  ({ showSkeleton, data, onClick }: FailedStatusByProps) => {
    const { isSeverity, colors } = getFailedStatusColorsBy(data);

    const onClickItemHandler = (key: string) => () => {
      if (typeof onClick === 'function') {
        onClick(key, isSeverity);
      }
    };

    const items: DoughnutDataItem[] = Object.entries(data || {})
      .map(([k, v]) => ({
        key: k,
        value: v,
        name: k,
        color: colors[k],
        onClick: onClickItemHandler(k),
        legend: {
          label: (
            <span className={styles.legendLabel}>
              <ColorBox color={colors[k]} />
              {k}
            </span>
          ),
          value: v,
        },
      }))
      ?.sort(sortFailedStatus);

    return (
      <DoughnutTile
        showSkeleton={showSkeleton}
        className={styles.tile}
        items={
          items?.length > 0
            ? items
            : [{ key: '', value: 100, color: '#FF0000' }]
        }
        classes={{ wrapper: styles.doughnutTileWrapper }}
        title={`Failed Status by ${isSeverity ? 'Severity' : 'Priority'}`}
      />
    );
  },
);

FailedStatusBy.displayName = 'FailedStatusBy';
