import { Icon } from 'components/icon';
import React, { useState } from 'react';
import cx from 'classnames';
import styles from '../styles/show-more.module.scss';

interface Props<T> {
  initialData: T[];
  totalCount?: number;
  rowsPerPage?: number;
}

export function useShowMore<T>({
  initialData,
  totalCount,
  rowsPerPage = 10,
}: Props<T>) {
  const [visibleRows, setVisibleRows] = useState<number>(rowsPerPage);

  const handleShowMore = () => {
    setVisibleRows((prev) => prev + rowsPerPage);
  };

  const resetVisibleRows = () => {
    setVisibleRows((prev) => rowsPerPage);
  };

  const displayedData = initialData;

  const totalRows = totalCount ?? (initialData?.length || 0);

  return {
    setVisibleRows,
    resetVisibleRows,
    data: displayedData,
    visibleRows,
    rowsPerPage,
    showMoreButton:
      visibleRows < totalRows ? (
        <div className={cx(styles.showMoreBtn)} onClick={handleShowMore}>
          Show More
          <Icon className={cx(styles.icon)} name="expand_more" />
        </div>
      ) : (
        <></>
      ),
  };
}
