import React from 'react';
import { Icon } from 'components/icon';
import styles from './styles.module.scss';
import {
  DataTableFilterMeta,
  DataTableFilterMetaData,
} from 'primereact/datatable';

interface TagProps {
  label: React.ReactNode;
  value?: React.ReactNode;
  onClear: () => void;
}

const Tag = ({
  label,
  value,
  children,
  onClear,
}: React.PropsWithChildren<TagProps>) => {
  return (
    <div className={styles.tag}>
      <div className={styles.frame}>
        <p className={styles.frameBody}>
          <span className={styles.label}>{label}</span>
          <span className={styles.span}>&nbsp;</span>
          <span className={styles.value}>{value || children}</span>
        </p>
      </div>
      <Icon onClick={onClear} className={styles.close} name="close" />
    </div>
  );
};

interface FilterTagsProps {
  titles: { [key: keyof DataTableFilterMeta]: string };
  filters: DataTableFilterMeta;
  onClear: (key: string, value: string) => void;
  onClearAll: () => void;
}

export const FilterTags = ({
  onClearAll,
  onClear,
  titles,
  filters,
}: FilterTagsProps) => {
  const list = Object.entries(filters);
  const filterList = (list || [])
    .filter(([key]) => key !== 'global')
    ?.map(([key, item], index) => {
      const { value } = (item || {}) as DataTableFilterMetaData;
      return (
        value &&
        value?.length > 0 &&
        (value || [])?.map((x: string, i: number) => (
          <Tag
            key={`filter_tag_${index}_${i}`}
            label={`${titles[key]}:`}
            onClear={() => {
              onClear(key, x);
            }}
            value={x}
          />
        ))
      );
    });

  const filteredList = filterList?.flat()?.filter((element) => {
    return element !== null && element !== undefined && element !== false;
  });

  return (
    <div className={styles.filterTags}>
      {filterList}
      {filteredList?.length > 0 && <ClearAll onClick={onClearAll} />}
    </div>
  );
};

interface ClearAllProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ClearAll = ({ onClick }: ClearAllProps) => {
  return (
    <button onClick={onClick} className={styles.clearAll}>
      <Icon className={styles.close} name="close" />
      <div className={styles.label}>Clear All</div>
    </button>
  );
};
