import { DataTableFilterMeta } from 'primereact/datatable';

export const defaultFilters: DataTableFilterMeta = {
  global: { value: null, matchMode: 'contains' },
  object: { value: null, matchMode: 'in' },
  resourceType: { value: null, matchMode: 'in' },
  namespace: { value: null, matchMode: 'in' },
  check: { value: null, matchMode: 'in' },
  result: { value: null, matchMode: 'in' },
  severity: { value: null, matchMode: 'in' },
};

export const filterTitles = {
  object: 'Object',
  resourceType: 'Resource Type',
  namespace: 'Namespace',
  check: 'Check',
  result: 'Result',
  severity: 'Severity',
};
