import React, { useCallback } from 'react';
import { Link, UIMatch, useMatches } from 'react-router-dom';
import { DataRoute, HandleRoute } from 'shared/models';
import styles from './styles.module.scss';

interface BreadcrumbsProps {
  showHomePage?: boolean;
}

export function Breadcrumbs<T>({ showHomePage }: BreadcrumbsProps) {
  const matches = useMatches() as UIMatch<DataRoute<T>, HandleRoute<T>>[];

  const checkHomePage = useCallback(
    (match: UIMatch<T, HandleRoute<T>>) => {
      if (showHomePage) {
        return true;
      }
      return match?.pathname !== '/';
    },
    [showHomePage],
  );

  const withCrumbs = matches
    // first get rid of any matches that don't have handle and crumb
    .filter(
      (match) =>
        checkHomePage(match) &&
        !!match?.handle?.crumb &&
        !!match?.handle?.title &&
        !match?.handle?.isIndex,
    )
    // now map them into an array of elements, passing the loader
    // data to each one
    .map((match) => ({
      ...match,
      crumb: match.handle.crumb(match.data),
    }));

  return (
    <ul className={styles.appBreadcrumbs}>
      {withCrumbs?.length > 1 &&
        withCrumbs.map((crumbData, index) => {
          return (
            <li key={index}>
              <Link to={crumbData.pathname}>{crumbData?.crumb}</Link>
            </li>
          );
        })}
    </ul>
  );
}
