import React from 'react';
import { Tile, TileProps } from 'components/tile';
import styles from './styles.module.scss';

interface TileGroupProps {
  showSkeleton?: boolean;
  tiles: TileProps[];
}

export const TileGroup = ({ tiles, showSkeleton }: TileGroupProps) => {
  return (
    <div className={styles.groupLayout}>
      {(tiles || []).map((x, i) => (
        <Tile key={`g-tile-item-${i}`} {...x} showSkeleton={showSkeleton} />
      ))}
    </div>
  );
};
