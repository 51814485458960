import { ReportTableDataItem } from '../models';
import { getTop } from '../utils';

export const chartTabs = (data: ReportTableDataItem[]) => [
  {
    id: 'checksTab',
    title: 'Checks',
    filter: 'check',
    data: getTop(data, 'check', 5),
  },
  {
    id: 'namespacesTab',
    title: 'Namespaces',
    filter: 'namespace',
    data: getTop(data, 'namespace', 5),
    isActive: true,
  },
  {
    id: 'resourcesTab',
    title: 'Resources',
    filter: 'resourceType',
    data: getTop(data, 'resourceType', 5),
  },
];
