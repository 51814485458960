import { useState, useEffect, useCallback } from 'react';
import { isEmpty } from 'shared/utils';

type KeyOf<T> = keyof T;

interface UseSearchParams<T> {
  data: T[];
  value: string;
  searchFields: KeyOf<T>[];
  delay: number;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function debounce<F extends (...args: any[]) => void>(
  func: F,
  delay: number,
): F {
  let timeoutId: ReturnType<typeof setTimeout>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (this: any, ...args: Parameters<F>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), delay);
  } as F;
}

export function useSearch<T>({
  value,
  data,
  searchFields,
  delay = 300,
}: UseSearchParams<T>) {
  const [query, setQuery] = useState<string>(value || '');
  const [results, setResults] = useState<T[]>(data);

  useEffect(() => {
    setQuery(value);
  }, [value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((searchQuery: string) => {
      if (isEmpty(searchQuery)) {
        setResults(data);
      } else {
        const lowerQuery = searchQuery.toLowerCase();
        const filteredData = (data || []).filter((item) =>
          (searchFields || [])?.some((field) =>
            String(item[field]).toLowerCase().includes(lowerQuery),
          ),
        );
        setResults(filteredData);
      }
    }, delay),
    [data, searchFields, delay],
  );

  useEffect(() => {
    handleSearch(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, handleSearch]);

  return { query, setQuery, results };
}
