import { Dialog } from 'primereact/dialog';
import React, { ReactNode, useState } from 'react';
import { DialogContext, DialogContextType, DialogState } from './context';

interface DialogProviderProps {
  children: ReactNode;
}

export const DialogProvider: React.FC<DialogProviderProps> = ({ children }) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogState, setDialogState] = useState<DialogState | null>(null);

  const openDialog = (newState: DialogState) => {
    setDialogState({ ...newState });
    setDialogVisible(true);
  };

  const closeDialog = () => {
    setDialogVisible(false);
    setDialogState(null);
  };

  const value: DialogContextType = {
    openDialog,
    closeDialog,
  };

  return (
    <DialogContext.Provider value={value}>
      {children}
      <Dialog
        header={dialogState?.header}
        footer={dialogState?.footer}
        visible={dialogVisible}
        modal={true}
        onHide={closeDialog}
      >
        {dialogState?.content}
      </Dialog>
    </DialogContext.Provider>
  );
};
