import React, { CSSProperties } from 'react';
import styles from './styles.module.scss';
import cx from 'classnames';

interface FieldProps {
  width?: CSSProperties['width'];
  label: React.ReactNode;
}

export const Field = ({
  label,
  width,
  children,
}: React.PropsWithChildren<FieldProps>) => {
  return (
    <div style={{ width }} className={cx(styles.field)}>
      <label className={cx(styles.label, styles.labelStyle)}>{label}</label>
      {children}
    </div>
  );
};
