import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router';

export function useFetchRefreshPage<T>(
  data: T,
  condition: (data: T) => boolean,
  effect: (data: T) => void,
  interval: number,
) {
  const timeout = useRef<NodeJS.Timeout>();
  const navigate = useNavigate();

  useEffect(() => {
    const refreshPage = () => {
      if (condition(data)) {
        navigate('.', { replace: true });
      }
    };
    if (condition(data)) {
      timeout.current = setTimeout(refreshPage, interval * 1000);
    }
    if (typeof effect === 'function') {
      effect(data);
    }
    return () => clearTimeout(timeout.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, navigate]);
}
