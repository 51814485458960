import { ToastMessage } from 'primereact/toast';
import { createContext, useContext } from 'react';
import { ApiError } from 'shared/api/client';

export interface ToastContextType {
  showToast: (message: ToastMessage | ToastMessage[]) => void;
  showApiError: (ex: ApiError) => void;
}

export const ToastContext = createContext<ToastContextType | undefined>(
  undefined,
);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
