import React from 'react';
import styles from './styles.module.scss';
import { Page } from 'components';

const IconError50x = ({ code }: { code: string }) => (
  <svg
    width="474"
    height="386"
    viewBox="0 0 474 386"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_704_8726)">
      <path
        d="M244.626 202.893H227.055V380.007H244.626V202.893Z"
        fill="#E3E9F2"
      />
      <path
        d="M244.626 202.961H226.987V209.085H244.626V202.961Z"
        fill="#E3E9F2"
      />
      <path
        d="M338.699 59.6739V144.066L279.124 203.74H194.872L135.301 144.066V59.6739L194.872 0H279.124L338.699 59.6739Z"
        fill="#E3E9F2"
      />
      <path
        d="M338.699 59.6736V144.065L279.124 203.739H194.872L155.012 163.813C304.822 185.927 306.379 27.2998 306.379 27.2998L338.702 59.6767L338.699 59.6736Z"
        fill="#CCD8EA"
      />
      <text
        id="dynamicErrorCode"
        x="50%"
        y="28%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="70"
        fontWeight="bold"
        fill=" #F25844"
      >
        {code}
      </text>
      <path
        d="M338.699 101.87C338.699 101.87 310.059 208.392 194.872 203.74H279.124L338.699 144.066V101.87Z"
        fill="#CCD8EA"
      />
      <path
        d="M328.815 63.7754V139.964L275.033 193.839H198.967L145.185 139.964V63.7754L198.967 9.90039H275.033L328.815 63.7754Z"
        stroke="white"
        strokeWidth="12"
        strokeMiterlimit="10"
      />
      <path
        d="M366.46 379.46H104.638C102.861 379.46 101.42 380.903 101.42 382.683V382.777C101.42 384.557 102.861 386 104.638 386H366.46C368.237 386 369.678 384.557 369.678 382.777V382.683C369.678 380.903 368.237 379.46 366.46 379.46Z"
        fill="#A8BDDB"
      />
      <path
        d="M109.6 379.46H67.2025C65.4254 379.46 63.9848 380.903 63.9848 382.683V382.777C63.9848 384.557 65.4254 386 67.2025 386H109.6C111.377 386 112.818 384.557 112.818 382.777V382.683C112.818 380.903 111.377 379.46 109.6 379.46Z"
        fill="#A8BDDB"
      />
      <path
        d="M47.7623 379.46H3.21765C1.44059 379.46 0 380.903 0 382.683V382.777C0 384.557 1.44059 386 3.21765 386H47.7623C49.5394 386 50.98 384.557 50.98 382.777V382.683C50.98 380.903 49.5394 379.46 47.7623 379.46Z"
        fill="#A8BDDB"
      />
      <path
        d="M422.983 379.46H386.418C384.641 379.46 383.2 380.903 383.2 382.683V382.777C383.2 384.557 384.641 386 386.418 386H422.983C424.76 386 426.2 384.557 426.2 382.777V382.683C426.2 380.903 424.76 379.46 422.983 379.46Z"
        fill="#A8BDDB"
      />
      <path
        d="M470.782 379.46H442.972C441.195 379.46 439.754 380.903 439.754 382.683V382.777C439.754 384.557 441.195 386 442.972 386H470.782C472.559 386 474 384.557 474 382.777V382.683C474 380.903 472.559 379.46 470.782 379.46Z"
        fill="#A8BDDB"
      />
      <path
        d="M201.227 367.031H108.798L110.48 360.935L113.763 349.025L115.754 341.816L118.685 331.184L124.337 310.692L127.271 300.061L147.884 225.325H162.144L169.696 252.716L182.754 300.061L185.688 310.692L191.34 331.184L194.273 341.816L199.544 360.935L201.227 367.031Z"
        fill="#E3E9F2"
      />
      <path
        d="M220.139 367.031H89.8883V379.436H220.139V367.031Z"
        fill="#CCD8EA"
      />
      <path
        d="M201.227 367.03H108.798L110.48 360.935H199.544L201.227 367.03Z"
        fill="#A8BDDB"
      />
      <path
        d="M185.688 310.692C185.688 310.692 183.753 351.61 136.82 360.969L199.548 360.935L185.691 310.692H185.688Z"
        fill="#CCD8EA"
      />
      <path
        d="M185.688 310.692H124.337L127.271 300.061H182.754L185.688 310.692Z"
        fill="white"
      />
      <path
        d="M194.273 341.815H115.754L118.685 331.184H191.34L194.273 341.815Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_704_8726">
        <rect width="474" height="386" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const Error50x = ({
  children,
  message,
}: React.PropsWithChildren<{ message: string }>) => {
  return (
    <Page
      pageStyle="summary"
      hideRightTemplate
      showHomePageInBreadcrumbs={false}
      className={styles.errorPage}
      title={() => null}
      breadcrumbs={false}
    >
      <IconError50x code="Error" />
      <h2 className={styles.title}>Service unavailable!</h2>
      <p className={styles.desc}>{message}</p>
      {children}
    </Page>
  );
};
