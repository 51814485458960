import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { Chart } from 'primereact/chart';
import {
  DoughnutControllerChartOptions,
  ChartData,
  ChartEvent,
  ActiveElement,
} from 'chart.js';
import { Skeleton } from 'primereact/skeleton';

export interface DoughnutDataItem {
  key: string;
  value: number;
  color: string;
  onClick?: () => void;
  hoverColor?: string;
  legend?: {
    label?: React.ReactNode;
    value?: React.ReactNode;
  };
}

interface DoughnutTileProps {
  showSkeleton?: boolean;
  title: string;
  items?: DoughnutDataItem[];
  className?: string;
  classes?: {
    wrapper?: string;
  };
}

export const DoughnutTile = React.memo(
  ({ title, items, showSkeleton, classes, className }: DoughnutTileProps) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
      const data: ChartData = {
        datasets: [
          {
            data: items?.map((x) => x?.value) || [],
            type: 'doughnut',
            backgroundColor: items?.map((x) => x?.color) || [], // ['#57AE60', '#F2994A', '#E84F40'],
            hoverBackgroundColor:
              items?.map((x) => x?.hoverColor || x?.color) || [], //['#57AE60', '#F2994A', '#E84F40'],
          },
        ],
      };
      const options: Partial<
        DoughnutControllerChartOptions & {
          onClick: (
            event: ChartEvent,
            elements: ActiveElement[],
            chart: Chart,
          ) => void;
        }
      > = {
        cutout: '70%',
        onClick: (event, elements) => {
          if (elements.length > 0 && items) {
            const { index } = elements[0];
            const item = items[index];
            if (typeof item?.onClick === 'function') {
              item?.onClick();
            }
          }
        },
      };

      setChartData(data);
      setChartOptions(options);
    }, [items]);

    return (
      <div className={cx(styles.doughnutWrapper, classes?.wrapper)}>
        <div className={cx(styles.doughnutTile, className)}>
          <h4 className={cx(styles.title)}>
            {showSkeleton ? <Skeleton width="8rem" height="1.5rem" /> : title}
          </h4>
          <div className={cx(styles.body)}>
            <div className={cx(styles.chart)}>
              {!showSkeleton ? (
                <Chart
                  type="doughnut"
                  data={chartData}
                  options={chartOptions}
                  className="w-full md:w-30rem"
                />
              ) : (
                <div className={styles.skeletonCircle}>
                  <Skeleton
                    className={styles.skeletonOne}
                    shape="circle"
                    size="9rem"
                  >
                    <div className={styles.skeletonOne}>
                      <Skeleton
                        className={styles.skeletonTwo}
                        shape="circle"
                        pt={{ root: { style: { background: '#fff' } } }}
                        size="6rem"
                      ></Skeleton>
                    </div>
                  </Skeleton>
                </div>
              )}
            </div>
            {(items || [])?.length > 0 && (
              <div className={cx(styles.legend)}>
                {items?.map((x, i) => {
                  return showSkeleton ? (
                    <div
                      key={`skeleton_legend_${x.value}_${i}`}
                      className={cx(styles.item)}
                    >
                      <Skeleton width="4rem" height="1.5rem" />{' '}
                      <Skeleton width="2.5rem" height="1.5rem" />
                    </div>
                  ) : (
                    <div
                      key={`legend_${x.value}_${i}`}
                      className={cx(styles.item)}
                      onClick={x?.onClick}
                    >
                      <div className={cx(styles.left)}>{x.legend?.label}</div>
                      {x.legend?.value && (
                        <div className={cx(styles.right)}>
                          {x.legend?.value}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  },
);

DoughnutTile.displayName = 'DoughnutTile';
