import { createContext, useContext } from 'react';
import { ReportModel, ReportTableDataItem } from 'pages/reports/shared/models';
import { DataTableFilterMeta, SortOrder } from 'primereact/datatable';

export interface CurrentReportState {
  loadedReports: { [key: string]: ReportData };
}

export interface SortByReportTable {
  sortField?: keyof ReportTableDataItem;
  sortOrder?: SortOrder;
}

export interface ReportData {
  data?: ReportModel | undefined | null;
  filters?: DataTableFilterMeta;
  sortBy?: SortByReportTable;
}

export interface CurrentReportContextType {
  loadedReports: { [key: string]: ReportData };
  filteredCounts: {
    [key: string]: number;
  };
  setLoadedReport: (data: ReportModel) => void;
  updateReport: (
    key: string,
    data: ReportModel | ((prev: ReportModel) => ReportModel),
  ) => void;
  setFilteredCount: (reportId: string, count: number) => void;
  setFilter: (
    reportId: string,
    data:
      | DataTableFilterMeta
      | ((prev: DataTableFilterMeta) => DataTableFilterMeta),
  ) => void;
  setSort: (
    reportId: string,
    data: SortByReportTable | ((prev: SortByReportTable) => SortByReportTable),
  ) => void;
  getReport: (id: string | undefined) => ReportData | null;
}

export const CurrentReportContext = createContext<
  CurrentReportContextType | undefined
>(undefined);

export const useCurrentReport = () => {
  const context = useContext(CurrentReportContext);
  if (!context) {
    throw new Error(
      'useCurrentReport must be used within a CurrentReportProvider',
    );
  }
  return context;
};
