import * as React from 'react';

export const useEffectUpdate = (
  effect: React.EffectCallback,
  deps?: React.DependencyList,
) => {
  const isFirstRender = React.useRef(true);

  React.useEffect(() => {
    if (!isFirstRender.current) {
      effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  React.useEffect(() => {
    isFirstRender.current = false;
  }, []);
};

export const useLayoutEffectUpdate = (
  effect: React.EffectCallback,
  deps?: React.DependencyList,
) => {
  const isFirstRender = React.useRef(true);

  React.useLayoutEffect(() => {
    if (!isFirstRender.current) {
      effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  React.useLayoutEffect(() => {
    isFirstRender.current = false;
  }, []);
};
