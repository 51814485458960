import React from 'react';
import { useCarousel } from 'hooks/use-carousel';
import { ActionKind } from 'hooks/use-carousel/models';
import { Icon } from 'components/icon';
import cx from 'classnames';
import styles from './styles.module.scss';
import ShowMoreLess from 'components/show-more-less';

interface CarouselProps {
  items: {
    id: string;
    content?: string | null;
    name: string;
    expanded?: boolean;
    animation?: boolean;
  }[];
}
function calculateCharactersToFit(width: number, fontSize: number): number {
  const charactersPerLine = Math.floor(width / fontSize);
  return charactersPerLine * 2 + 1;
}

export const Carousel = ({ items }: CarouselProps) => {
  const {
    getSectionProps,
    getCarouselWrapperProps,
    getCarouselProps,
    getCarouselItemProps,
    getButtonProps,
    sectionRef,
    state,
  } = useCarousel({ numItems: items?.length });

  return (
    <div {...getSectionProps()}>
      <div {...getCarouselWrapperProps()}>
        <ul className={cx(styles.carouselBody)} {...getCarouselProps()}>
          {items.map((item, idx) => {
            const clientWidth = sectionRef?.current?.clientWidth || 0;
            const size = calculateCharactersToFit(clientWidth, 10) || 80;
            return (
              <li
                className={cx(styles.content)}
                key={idx}
                {...getCarouselItemProps(idx)}
              >
                <ShowMoreLess
                  expanded={item.expanded}
                  animation={item.animation}
                  text={item.content || ''}
                  maxLength={size}
                />
              </li>
            );
          })}
        </ul>
      </div>
      {items?.length > 1 && (
        <div className={cx(styles.control)}>
          <button
            disabled={state.activeSlideIndex === 0}
            className={cx(styles.navButton)}
            {...getButtonProps(ActionKind.Previous)}
          >
            <Icon
              className={cx(styles.icon)}
              size={'1.5rem'}
              name="chevron_left"
            />
          </button>
          <span className={cx(styles.slideName)}>
            {items[state.activeSlideIndex]?.name}
          </span>
          <button
            disabled={state.activeSlideIndex === items?.length - 1}
            className={cx(styles.navButton)}
            {...getButtonProps(ActionKind.Next)}
          >
            <Icon
              className={cx(styles.icon)}
              size={'1.5rem'}
              name="chevron_right"
            />
          </button>
        </div>
      )}
    </div>
  );
};
