import { Params } from 'react-router-dom';
import {
  ClustersService,
  ClusterCheckResult,
  ClusterReport,
} from 'shared/api/client';
import { getProperty, normalizeArray } from 'shared/utils';
import {
  ClusterCheckModel,
  ClusterReportModel,
  FailedCheckModel,
} from './models';

export const getChecks =
  (executionDate: string) =>
  ({ clusterId }: Params<string>) => {
    if (clusterId) {
      return ClustersService.getClusterChecksApiV1ClustersClusterIdChecksGet({
        clusterId,
        executionDate,
      }).then((res) => {
        return normalizeArray({
          array: res?.checks || [],
          key: 'name' as keyof ClusterCheckResult,
          iterator: (x) => {
            const key = 'name' as keyof ClusterCheckResult;
            return {
              name: getProperty(x, key),
              count: x.count,
              status: x?.status,
              checkId: x?.check_id,
              severity: x?.severity,
              priority: x?.priority,
            } as ClusterCheckModel;
          },
        });
      });
    }
    return Promise.reject(`The "clusterId" parameter is missing`);
  };

export const getReports = ({ clusterId }: Params<string>) => {
  if (clusterId) {
    return ClustersService.getClusterReportsApiV1ClustersClusterIdReportsGet({
      clusterId,
    }).then((res) => {
      return normalizeArray({
        array: res?.reports || [],
        key: 'id' as keyof ClusterReport,
        iterator: (x) => {
          const key = 'id' as keyof ClusterReport;
          return {
            id: getProperty(x, key),
            name: getProperty(x, 'name' as keyof ClusterReport),
            date: x?.date,
            noResult: x?.no_result,
            failCount: x?.failed,
          } as ClusterReportModel;
        },
      });
    });
  }
  return Promise.reject(`The "clusterId" parameter is missing`);
};

export const getFailures =
  (executionDate: string) =>
  ({ clusterId }: Params<string>) => {
    if (clusterId) {
      return ClustersService.getClusterFailuresApiV1ClustersClusterIdFailuresGet(
        {
          clusterId,
          executionDate,
        },
      ).then((res) => {
        return normalizeArray({
          array: res?.failures || [],
          key: (d) => `${d?.name}-${d?.check_title}`,
          iterator: (x) => {
            return {
              name: x?.name,
              title: x?.check_title,
              status: x?.status,
            } as FailedCheckModel;
          },
        });
      });
    }
    return Promise.reject(`The "clusterId" parameter is missing`);
  };
