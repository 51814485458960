import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import styles from '../styles.module.scss';
import { TileGroup } from '../tile-group';
import { StackedBar, StackedBarDataItem } from 'components/stacked-bar';
import { TileProps, TimeRange } from 'components';
import { useCheckSummary } from '../context';
import { getChecksEvolution } from '../shared/requests';
import { getChecksEvolutionChart } from '../shared/utils';
import { useToast } from 'shared/providers/toast/context';

interface DashboardChartsProps {
  clusterID?: string;
  showSkeleton: boolean;
  tiles: TileProps[];
  doughnutsCharts: React.ReactNode;
}

interface State {
  data: StackedBarDataItem[];
  labels: string[];
}

export const DashboardCharts = React.memo(
  ({
    clusterID,
    tiles,
    doughnutsCharts,
    showSkeleton,
  }: DashboardChartsProps) => {
    const { timeRange, setTimeRange } = useCheckSummary();
    const { showApiError } = useToast();

    const [chartData, setData] = useState<State>({
      data: [],
      labels: ['Today'],
    });

    useEffect(() => {
      getChecksEvolution({
        clusterId: clusterID,
        option: timeRange?.value,
        customStartDate: timeRange?.from,
        customEndDate: timeRange?.to,
      })
        ?.then((res) => {
          setData(getChecksEvolutionChart(res));
        })
        ?.catch((e) => {
          showApiError(e);
        });
    }, [timeRange, clusterID, showApiError]);

    return (
      <div className={cx(styles.charts)}>
        <div style={{ flexGrow: 'unset' }} className={cx(styles.tiles)}>
          <TileGroup showSkeleton={showSkeleton} tiles={tiles} />
        </div>
        <div className={cx(styles.doughnuts)}>{doughnutsCharts}</div>
        <div className={cx(styles.checkEvolution)}>
          <StackedBar
            data={chartData?.data || []}
            labels={chartData?.labels || []}
            height={'8.5rem'}
            title="Check Evolution"
            headerTemplate={(title) => {
              return (
                <>
                  {title}
                  <TimeRange
                    rangeValue={timeRange?.value}
                    from={timeRange?.from}
                    to={timeRange?.to}
                    onChange={(v, d) => {
                      setTimeRange((prev) => ({ ...prev, value: v, ...d }));
                    }}
                  />
                </>
              );
            }}
          />
        </div>
      </div>
    );
  },
);

DashboardCharts.displayName = 'DashboardCharts';

export const DashboardView = ({ children }: React.PropsWithChildren) => {
  return <div className={cx(styles.checkSummary)}>{children}</div>;
};
