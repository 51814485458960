import { TimeRangeValue } from 'components/time-range/models';
import React, { useState } from 'react';
import {
  ClusterCheckModel,
  ClusterReportModel,
  ClusterViewItemValue,
  FailedCheckModel,
} from '../../shared/models';
import { CurrentClusterContext, CurrentClusterContextType } from './';

interface CurrentClusterProviderProps {}

export const CurrentClusterProvider: React.FC<
  React.PropsWithChildren<CurrentClusterProviderProps>
> = ({ children }) => {
  const [view, setView] = useState<ClusterViewItemValue>('checks');
  const [checks, setChecks] = useState<
    Record<string, Record<string, ClusterCheckModel>>
  >({});
  const [reports, setReports] = useState<
    Record<string, Record<string, ClusterReportModel>>
  >({});
  const [failures, setFailures] = useState<
    Record<string, Record<string, FailedCheckModel>>
  >({});
  const [timeRange, setTimeRange] = useState<{
    value?: TimeRangeValue;
    from?: string;
    to?: string;
  }>({ value: 'last_5_days' });

  const value: CurrentClusterContextType = {
    view,
    failures,
    reports,
    checks,
    timeRange,
    setChecks,
    setFailures,
    setReports,
    setTimeRange,
    setView,
  };

  return (
    <CurrentClusterContext.Provider value={value}>
      {children}
    </CurrentClusterContext.Provider>
  );
};
