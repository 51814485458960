import { TimeRangeItem } from './models';

export const rangeValues: TimeRangeItem[] = [
  { label: 'Today', value: 'today' },
  { label: 'Last 5 days', value: 'last_5_days' },
  { label: 'Last week', value: 'last_week' },
  { label: 'Last month', value: 'last_month' },
  { label: 'Last year', value: 'last_year' },
  { label: 'Custom', value: 'custom' },
];
