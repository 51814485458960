import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { User } from '@auth0/auth0-react';
import axios from 'axios';

type AvatarProps = {
  user: User;
  className?: string;
};

const DefaultAvatar = ({ user, className }: AvatarProps) => {
  const generateColor = (str: string): string => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = Math.floor(
      Math.abs(((Math.sin(hash) * 10000) % 1) * 16777216),
    ).toString(16);
    return '#' + '000000'.substring(0, 6 - color.length) + color;
  };

  const avatarStyle: React.CSSProperties = {
    backgroundColor: generateColor(user?.nickname || ''),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '1.2rem',
  };

  const getInitials = (nickname: string): string => {
    const parts = nickname.split('.');
    const initials = parts.map((part) => part.substring(0, 1).toUpperCase());
    return initials.join('');
  };

  return (
    <div className={className} style={avatarStyle}>
      {getInitials(user?.nickname || '')}
    </div>
  );
};

export const Avatar = ({ className, user }: AvatarProps) => {
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchAvatar = async () => {
      try {
        if (user?.picture) {
          const response = await axios(user?.picture);
          if (response.status === 200) {
            setAvatarUrl(user?.picture || '');
          }
        }
      } catch (error) {
        setAvatarUrl(null);
      }
    };
    fetchAvatar();
  }, [user]);

  if (avatarUrl) {
    return <img className={cx(className)} src={avatarUrl} alt="avatar" />;
  }
  return <DefaultAvatar className={cx(className)} user={user} />;
};

export default Avatar;
