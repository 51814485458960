import React, { useState } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { useEffectUpdate } from 'hooks/use-effect-update';
import { TypeAnimation } from 'react-type-animation';

interface ShowMoreLessProps {
  text: string;
  maxLength: number;
  expanded?: boolean;
  animation?: boolean;
}

export const ShowMoreLess = ({
  text,
  expanded,
  animation,
  maxLength,
}: ShowMoreLessProps) => {
  const [showFullText, setShowFullText] = useState(expanded);
  const [animationEffect, setAnimationEffect] = useState(animation);

  const toggleShowText = () => {
    setShowFullText(!showFullText);
  };

  useEffectUpdate(() => {
    setAnimationEffect(animation);
  }, [animation]);

  useEffectUpdate(() => {
    setShowFullText(expanded);
  }, [expanded]);

  if (text?.length <= maxLength) {
    return <div>{text}</div>;
  }

  return (
    <p>
      {showFullText ? (
        <span>
          {animationEffect ? (
            <TypeAnimation
              sequence={[
                text,
                () => {
                  setAnimationEffect(false);
                },
              ]}
              speed={86}
            />
          ) : (
            text
          )}
          {!animationEffect && (
            <button className={cx(styles.button)} onClick={toggleShowText}>
              Show less
            </button>
          )}
        </span>
      ) : (
        <span>
          {text.slice(0, maxLength)}
          {text.length > maxLength && '...'}
          <button className={cx(styles.button)} onClick={toggleShowText}>
            Show more
          </button>
        </span>
      )}
    </p>
  );
};

export default ShowMoreLess;
