import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { toCapitalize } from 'shared/utils';

export type SeverityType = 'critical' | 'severe' | 'high' | 'medium' | 'low';

export interface SeverityProps {
  type?: 'critical' | 'severe' | 'high' | 'medium' | 'low';
}

export const Severity = ({
  children,
  type,
}: React.PropsWithChildren<SeverityProps>) => {
  if (!type) {
    return <></>;
  }

  return (
    <span className={cx(styles.severity, styles[type])}>
      <span className={cx(styles.label, styles[type])}>
        {children || toCapitalize(type)}
      </span>
    </span>
  );
};
