import { useSearch } from 'hooks/use-search';
import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { InputText } from 'primereact/inputtext';
import { Icon } from 'components/icon';
import { isEmpty } from 'shared/utils';

interface SearchProps<T> {
  data: T[];
  value: string;
  className?: string;
  searchFields: (keyof T)[];
  resultsComponent: (results: T[], query: string) => React.ReactNode;
}

export function Search<T>({
  data,
  value,
  className,
  searchFields,
  resultsComponent,
}: React.PropsWithChildren<SearchProps<T>>) {
  const { query, setQuery, results } = useSearch<T>({
    value,
    data,
    delay: 330,
    searchFields,
  });
  const showEmptySearch =
    data?.length > 0 && results.length === 0 && !isEmpty(query);
  return (
    <div className={cx(styles.search, className)}>
      <div className={styles.inputWrapper}>
        <InputText
          placeholder="Search"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Icon size={'1.5rem'} className={styles.icon} name="search" />
      </div>
      {showEmptySearch ? (
        <div className={styles.empty}>
          <p className={styles.text}>No matches were found for this search</p>
        </div>
      ) : (
        resultsComponent(results, query)
      )}
    </div>
  );
}
