import React, { useEffect, useRef } from 'react';
import { isEqual } from 'shared/utils';

export function useDeepCompareEffect(
  callback: React.EffectCallback,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dependencies: any[],
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dependenciesRef = useRef<any[]>([]);

  if (!isEqual(dependencies, dependenciesRef.current)) {
    dependenciesRef.current = dependencies;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependenciesRef.current);
}
