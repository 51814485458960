import React from 'react';
import { DoughnutDataItem, DoughnutTile, Icon } from 'components';
import styles from '../styles.module.scss';
import { formatNumber } from 'shared/utils';
import { CheckStatus } from 'shared/api/client';

interface OverallByStatusProps {
  showSkeleton?: boolean;
  total: number;
  passed: number;
  failed: number;
  noResult: number;
  onClick?: (key: CheckStatus) => void;
}

export const OverallByStatus = React.memo(
  ({
    showSkeleton,
    total,
    noResult,
    passed,
    failed,
    onClick,
  }: OverallByStatusProps) => {
    const passedPrecent = (passed / total) * 100 || 0;
    const failedPrecent = (failed / total) * 100 || 0;
    const noResultPrecent = (noResult / total) * 100 || 0;

    const onClickHandler = (key: CheckStatus) => () => {
      if (typeof onClick === 'function') {
        onClick(key);
      }
    };

    const items: DoughnutDataItem[] = [
      {
        key: 'passed',
        value: passed,
        color: '#57AE60',
        onClick: onClickHandler('PASS'),
        legend: {
          label: (
            <span className={styles.legendLabel}>
              <Icon size={'1.5rem'} color={'#57AE60'} name="pass" />
              Passed
            </span>
          ),
          value: `${formatNumber(passedPrecent)}%`,
        },
      },
      {
        key: 'failed',
        value: failed,
        color: '#E84F40',
        onClick: onClickHandler('FAIL'),
        legend: {
          label: (
            <span className={styles.legendLabel}>
              <Icon size={'1.5rem'} color={'#E84F40'} name="fail" />
              Failed
            </span>
          ),
          value: `${formatNumber(failedPrecent)}%`,
        },
      },
      {
        key: 'noResult',
        value: total > 0 ? noResultPrecent : 100,
        color: '#F2994A',
        onClick: onClickHandler('ERROR'),
        legend: {
          label: (
            <span className={styles.legendLabel}>
              <Icon size={'1.5rem'} color={'#F2994A'} name="error" />
              No Result
            </span>
          ),
          value: `${formatNumber(noResultPrecent)}%`,
        },
      },
    ];
    return (
      <DoughnutTile
        className={styles.tile}
        showSkeleton={showSkeleton}
        items={items}
        classes={{ wrapper: styles.doughnutTileWrapper }}
        title="Overall by Status"
      />
    );
  },
);

OverallByStatus.displayName = 'OverallByStatus';
