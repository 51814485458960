import {
  ColumnFilterClearTemplateOptions,
  ColumnFilterElementTemplateOptions,
} from 'primereact/column';
import React from 'react';
import { DataGridColumn } from '.';
import { ColumnFilterTemplate } from './column-filter';
import { FilterFooter } from './column-filter/footer';

export function getFilterItems<T>(items: T[], columnProps: DataGridColumn<T>) {
  if (columnProps?.field) {
    return (items || []).filter(
      (x) => columnProps?.field && (x || ({} as T))[columnProps?.field],
    );
  }
  return items || [];
}

export function columWithFilter<T>(
  items: T[],
  options: DataGridColumn<T>,
): DataGridColumn<T> {
  return {
    showFilterMatchModes: false,
    filterField: options.filterField,
    filterApply: <></>,
    sortOptionsInFilter: options?.sortOptionsInFilter,
    filterClear: (opt: ColumnFilterClearTemplateOptions) => {
      const hasFilter = !!opt.filterModel;
      return hasFilter && items?.length > 0 ? <FilterFooter {...opt} /> : <></>;
    },
    filterElement: (optionsFilter: ColumnFilterElementTemplateOptions) => {
      return (
        <ColumnFilterTemplate
          columnProps={options}
          items={items}
          optionsFilter={optionsFilter}
        />
      );
    },
  };
}
