import React from 'react';
import { Skeleton } from 'primereact/skeleton';
import cx from 'classnames';
import styles from '../charts/styles.module.scss';

interface WithSkeletonBarChartProps {
  showSkeleton: boolean;
}

export const WithSkeletonBarChart = ({
  children,
  showSkeleton,
}: React.PropsWithChildren<WithSkeletonBarChartProps>) => {
  const data = [
    { width1: '20%', height1: '1rem', width2: '80%', height2: '1.6rem' },
    { width1: '20%', height1: '1rem', width2: '75%', height2: '1.6rem' },
    { width1: '20%', height1: '1rem', width2: '56%', height2: '1.6rem' },
    { width1: '20%', height1: '1rem', width2: '34%', height2: '1.6rem' },
    { width1: '20%', height1: '1rem', width2: '23%', height2: '1.6rem' },
  ];
  return (
    <>
      {showSkeleton && (
        <div className={cx(styles.skeletonBarChart)}>
          {data.map((item, index) => (
            <div key={index} className={styles.sklBarRow}>
              <Skeleton
                width={item.width1}
                height={item.height1}
                className={'mb-2'}
              />
              <Skeleton
                width={item.width2}
                height={item.height2}
                className={'mb-2'}
              />
            </div>
          ))}
        </div>
      )}
      <div
        className={cx(styles.chart, { [styles.withSkeleton]: showSkeleton })}
      >
        {children}
      </div>
    </>
  );
};
