import { TimeRangeValue } from 'components/time-range/models';
import {
  ClusterCheckModel,
  ClusterReportModel,
  ClusterViewItemValue,
  FailedCheckModel,
} from 'pages/check-summary/shared/models';
import { createContext, useContext } from 'react';

export interface CurrentClusterState {
  view: ClusterViewItemValue;
  checks: Record<string, Record<string, ClusterCheckModel>>;
  failures: Record<string, Record<string, FailedCheckModel>>;
  reports: Record<string, Record<string, ClusterReportModel>>;
  timeRange: { value?: TimeRangeValue; from?: string; to?: string };
}

export interface CurrentClusterContextType {
  view: ClusterViewItemValue;
  checks: Record<string, Record<string, ClusterCheckModel>>;
  reports: Record<string, Record<string, ClusterReportModel>>;
  failures: Record<string, Record<string, FailedCheckModel>>;
  timeRange?: { value?: TimeRangeValue; from?: string; to?: string };
  setView: React.Dispatch<React.SetStateAction<ClusterViewItemValue>>;
  setReports: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, ClusterReportModel>>>
  >;
  setChecks: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, ClusterCheckModel>>>
  >;
  setFailures: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, FailedCheckModel>>>
  >;
  setTimeRange: React.Dispatch<
    React.SetStateAction<{
      value?: TimeRangeValue;
      from?: string | undefined;
      to?: string | undefined;
    }>
  >;
}

export const CurrentClusterContext = createContext<
  CurrentClusterContextType | undefined
>(undefined);

export const useCurrentCluster = () => {
  const context = useContext(CurrentClusterContext);
  if (!context) {
    throw new Error(
      'useCurrentCluster must be used within a CurrentClusterProvider',
    );
  }
  return context;
};
