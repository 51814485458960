import React, { ReactNode } from 'react';
import { AuthContext, AuthContextType } from './context';
import { Auth0Provider } from '@auth0/auth0-react';
import { RequireAuth } from './require-auth';
import { useNavigate } from 'react-router-dom';

interface AuthProviderProps {
  children: ReactNode;
}

const AUTH0_DOMAIN = window.__RUNTIME_CONFIG__.AUTH0_DOMAIN;
const AUTH0_CLIENT_ID = window.__RUNTIME_CONFIG__.AUTH0_CLIENT_ID;
const AUTH0_AUDIENCE = window.__RUNTIME_CONFIG__.AUTH0_AUDIENCE;

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const value: AuthContextType = {};
  const navigate = useNavigate();

  return (
    <AuthContext.Provider value={value}>
      <Auth0Provider
        domain={AUTH0_DOMAIN}
        clientId={AUTH0_CLIENT_ID}
        useRefreshTokens
        onRedirectCallback={(appState) => {
          navigate(appState?.returnTo || window.location.pathname);
        }}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: AUTH0_AUDIENCE,
        }}
      >
        <RequireAuth>{children}</RequireAuth>
      </Auth0Provider>
    </AuthContext.Provider>
  );
};
