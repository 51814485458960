import { Icon } from 'components/icon';
import { usePageContext } from 'components/page';
import { useEffectUpdate } from 'hooks';
import React from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getAssetsPath } from 'shared/utils';
import { PossibleParams, PossibleState } from './models';
import styles from './styles.module.scss';
import { Profile } from 'components/profile';
import { useAuth0 } from '@auth0/auth0-react';

export const Header = () => {
  const { isAuthenticated } = useAuth0();
  const params = useParams<PossibleParams>();

  const navigate = useNavigate();

  const { state, setState } = usePageContext<PossibleState>();

  // important for current report page
  useEffectUpdate(() => {
    if (state?.isError404) {
      setState((prev) => ({ ...prev, isError404: false, isLoading: true }));
    }
  }, [state, params]);

  const navigateToHome = () => {
    navigate('/');
  };

  return (
    <div className={styles.appHeader}>
      <img
        onClick={navigateToHome}
        className={styles.logo}
        src={getAssetsPath('logo')}
        alt="logo"
      />
      {isAuthenticated && (
        <div className={styles.menu}>
          <Link className={styles.item} to="/">
            <Icon className={styles.icon} size={'1.5rem'} name="chart" />
            Check Summary
          </Link>
        </div>
      )}
      <div className={styles.right}>
        <Profile />
      </div>
    </div>
  );
};
