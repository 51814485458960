import React, { useEffect, useState } from 'react';
import { ReportTableDataItem } from 'pages/reports/shared/models';
import cx from 'classnames';
import styles from './styles.module.scss';
import { Carousel } from 'components/carousel';

interface ExplanationColumnBodyProps {
  row: ReportTableDataItem;
}

interface Explanation {
  id: string;
  content?: string | null;
  name: string;
  order: number;
  expanded?: boolean;
  animation?: boolean;
}

export const ExplanationColumnCell = ({ row }: ExplanationColumnBodyProps) => {
  const [explanations, setExplanations] = useState<Explanation[]>([]);

  useEffect(() => {
    const explanationsList: Explanation[] = [
      {
        id: 'explanation',
        content: row?.explanation,
        name: 'System Explanation',
        animation: false,
        order: 1,
      },
    ];

    setExplanations(explanationsList);
  }, [row]);

  return (
    <div className={cx(styles.explanationCell)}>
      <Carousel
        items={(explanations || [])?.sort((a, b) => a.order - b.order)}
      />
    </div>
  );
};
