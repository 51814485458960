import React from 'react';
import { Icon } from 'components/icon';
import { ColumnFilterClearTemplateOptions } from 'primereact/column';

import styles from '../styles.module.scss';

interface FilterFooterProps extends ColumnFilterClearTemplateOptions {}

export const FilterFooter = ({ filterClearCallback }: FilterFooterProps) => {
  return (
    <div onClick={filterClearCallback} className={styles.clearAllBtn}>
      <Icon size={'1.5rem'} name="close" />
      <label className={styles.label}>Clear All</label>
    </div>
  );
};
