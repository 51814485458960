/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetCheckEvolutionResponse } from '../models/GetCheckEvolutionResponse';
import type { GetChecksEvolutionRequest } from '../models/GetChecksEvolutionRequest';
import type { GetClusterCheckResultsResponse } from '../models/GetClusterCheckResultsResponse';
import type { GetClusterDashboardResponse } from '../models/GetClusterDashboardResponse';
import type { GetClusterFailuresResponse } from '../models/GetClusterFailuresResponse';
import type { GetClusterReportsResponse } from '../models/GetClusterReportsResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ClustersService {
  /**
   * Get Cluster Dashboard
   * @returns GetClusterDashboardResponse Successful Response
   * @throws ApiError
   */
  public static getClusterDashboardApiV1ClustersDashboardGet({
    executionDate,
    suiteId,
  }: {
    executionDate?: string | null;
    suiteId?: string | null;
  }): CancelablePromise<GetClusterDashboardResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/clusters/dashboard',
      query: {
        execution_date: executionDate,
        suite_id: suiteId,
      },
      errors: {
        400: `Bad request`,
        404: `Not found`,
        422: `Validation Error`,
        500: `Internal error`,
      },
    });
  }
  /**
   * Get Cluster Checks
   * @returns GetClusterCheckResultsResponse Successful Response
   * @throws ApiError
   */
  public static getClusterChecksApiV1ClustersClusterIdChecksGet({
    clusterId,
    executionDate,
  }: {
    clusterId: string;
    executionDate?: string | null;
  }): CancelablePromise<GetClusterCheckResultsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/clusters/{cluster_id}/checks',
      path: {
        cluster_id: clusterId,
      },
      query: {
        execution_date: executionDate,
      },
      errors: {
        400: `Bad request`,
        404: `Not found`,
        422: `Validation Error`,
        500: `Internal error`,
      },
    });
  }
  /**
   * Get Cluster Failures
   * @returns GetClusterFailuresResponse Successful Response
   * @throws ApiError
   */
  public static getClusterFailuresApiV1ClustersClusterIdFailuresGet({
    clusterId,
    executionDate,
  }: {
    clusterId: string;
    executionDate?: string | null;
  }): CancelablePromise<GetClusterFailuresResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/clusters/{cluster_id}/failures',
      path: {
        cluster_id: clusterId,
      },
      query: {
        execution_date: executionDate,
      },
      errors: {
        400: `Bad request`,
        404: `Not found`,
        422: `Validation Error`,
        500: `Internal error`,
      },
    });
  }
  /**
   * Get Cluster Reports
   * @returns GetClusterReportsResponse Successful Response
   * @throws ApiError
   */
  public static getClusterReportsApiV1ClustersClusterIdReportsGet({
    clusterId,
  }: {
    clusterId: string;
  }): CancelablePromise<GetClusterReportsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/clusters/{cluster_id}/reports',
      path: {
        cluster_id: clusterId,
      },
      errors: {
        400: `Bad request`,
        404: `Not found`,
        422: `Validation Error`,
        500: `Internal error`,
      },
    });
  }
  /**
   * Get Checks Evolution
   * @returns GetCheckEvolutionResponse Successful Response
   * @throws ApiError
   */
  public static getChecksEvolutionApiV1ClustersChecksEvolutionPost({
    requestBody,
  }: {
    requestBody: GetChecksEvolutionRequest;
  }): CancelablePromise<GetCheckEvolutionResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/clusters/checks-evolution',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad request`,
        404: `Not found`,
        422: `Validation Error`,
        500: `Internal error`,
      },
    });
  }
  /**
   * Sync
   * @returns void
   * @throws ApiError
   */
  public static syncApiV1ClustersSyncPost(): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/clusters/sync',
      errors: {
        400: `Bad request`,
        404: `Not found`,
        500: `Internal error`,
      },
    });
  }
}
