import React from 'react';
import { ColumnFilterElementTemplateOptions } from 'primereact/column';
import { ListBox, ListBoxChangeEvent } from 'primereact/listbox';
import { Checkbox } from 'primereact/checkbox';
import styles from '../styles.module.scss';
import { isObjectInArray } from 'components/utils';
import { DataGridColumn } from '..';

interface ColumnFilterTemplateProps<T> {
  items: T[];
  columnProps: DataGridColumn<T>;
  optionsFilter: ColumnFilterElementTemplateOptions;
}

type ItemTemplateProps<T> = T & {
  checked: boolean;
  label: string;
};

function ItemTemplate<T>(props: ItemTemplateProps<T>) {
  const { checked, label } = props;
  return (
    <div className={styles.filterColumnItem}>
      <Checkbox className={styles.chbox} checked={checked} />
      <span>{label}</span>
    </div>
  );
}

export function ColumnFilterTemplate<T>({
  columnProps,
  items,
  optionsFilter,
}: ColumnFilterTemplateProps<T>) {
  const uniqueList: Set<T[keyof T]> = new Set(
    items.map((x) => (x || {})[columnProps?.filterField as string]),
  ) as Set<T[keyof T]>;

  let listItems = Array.from(uniqueList);

  if (typeof columnProps?.sortOptionsInFilter === 'function') {
    listItems = (listItems || [])?.sort(columnProps?.sortOptionsInFilter);
  }

  return (
    <ListBox
      value={optionsFilter.value}
      multiple
      filter
      filterBy={columnProps.filterField}
      filterPlaceholder={`Search ${columnProps.header}...`}
      virtualScrollerOptions={{ itemSize: 38 }}
      className="w-full md:w-14rem"
      listStyle={{ height: '250px' }}
      options={listItems}
      itemTemplate={(opt) => (
        <ItemTemplate
          label={opt}
          optionsFilter={optionsFilter}
          fieldName={columnProps?.filterField}
          checked={isObjectInArray(optionsFilter?.value || [], opt)}
        />
      )}
      onChange={(e: ListBoxChangeEvent) => {
        optionsFilter.filterApplyCallback(e.value);
      }}
      style={{ minWidth: '20rem' }}
    />
  );
}
