import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { Icon } from 'components/icon';

export interface ResultStatusProps {
  type?: 'PASS' | 'FAIL' | 'ERROR' | 'IGNORE' | 'UNKNOWN';
}

export const ResultStatus = ({
  children,
  type,
}: React.PropsWithChildren<ResultStatusProps>) => {
  if (!type) {
    return <></>;
  }
  const status = (type || '')?.toLowerCase();
  return (
    <span className={cx(styles.resultStatus, styles[status])}>
      <Icon className={cx(styles[status])} size={'1.5rem'} name={status} />
      <span className={cx(styles.label, styles[status])}>{children}</span>
    </span>
  );
};
