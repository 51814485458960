import { useAuth0 } from '@auth0/auth0-react';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect } from 'react';
import { Layout } from '../../../shell';
import { Error50x } from 'pages/error-page/error-code';
import { OpenAPI } from 'shared/api/client';
import { useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';

export const RequireAuth = ({ children }: React.PropsWithChildren) => {
  const location = useLocation();
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading && !user && !error) {
      loginWithRedirect({
        authorizationParams: {
          signInWithCredentials: false,
          signUpSupport: false,
        },
        appState: {
          returnTo: location.pathname,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isLoading, user, error]);

  useEffect(() => {
    const getToken = async () => {
      const jwt = await getAccessTokenSilently({});
      return jwt;
    };

    if (OpenAPI) {
      OpenAPI.TOKEN = getToken;
      OpenAPI.WITH_CREDENTIALS = true;
    }
  }, [isAuthenticated, user, getAccessTokenSilently]);

  if (isLoading) {
    return (
      <Layout>
        <div className="absolute">
          <div
            style={{
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            <ProgressSpinner />
          </div>
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Error50x message={error?.message || ''}>
          <Button onClick={(e) => logout()}>
            Sign in with another account
          </Button>
        </Error50x>
      </Layout>
    );
  }

  if (!isAuthenticated) {
    return <Layout />;
  }

  return <>{children}</>;
};
