import { toCapitalize } from 'shared/utils';
import { ReportTableDataItem } from '../models';
import {
  app__schemas__dto__checks__CheckResult,
  Check,
} from 'shared/api/client';

export function getTop(
  objects: ReportTableDataItem[],
  field: keyof ReportTableDataItem,
  topCount: number,
): { name: string; count: number }[] {
  const counts: { [key: string]: number } = {};

  (objects || [])
    .filter((x) => x.status === 'FAIL')
    .forEach((object) => {
      const data = object[field];
      if (data) {
        counts[data] = (counts[data] || 0) + 1;
      }
    });

  const arr = Object.keys(counts).map((namespace) => ({
    name: namespace,
    count: counts[namespace],
  }));

  arr.sort((a, b) => b.count - a.count);

  return arr.slice(0, topCount);
}

export const mapCheckResults = (
  checkResults: app__schemas__dto__checks__CheckResult[],
  checkList: Record<string, Check>,
): ReportTableDataItem[] => {
  return (checkResults || [])?.map(
    (x) =>
      ({
        object: x?.object_name,
        resourceType: x?.object_meta?.resource_type,
        namespace: x?.object_meta?.resource_namespace,
        checkId: x?.check_id,
        checkResultId: x?.id,
        executionId: x?.execution_id,
        check: ((checkList || {})[x?.check_id] || {})?.title ?? x?.check_id,
        status: x.status,
        severity: toCapitalize(
          ((checkList || {})[x?.check_id] || {})?.severity?.toLowerCase(),
        ),
        result: toCapitalize((x?.status || '')?.toLowerCase()),
        explanation: x?.explanation,
      }) as ReportTableDataItem,
  );
};
