/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import {
  ActiveElement,
  ChartEvent,
  Chart as ChartModel,
  ChartOptions,
} from 'chart.js';
import { TabOption } from 'pages/reports/shared/models';
import { ChartData } from './models';
import { WithSkeletonBarChart } from '../skeleton-templates/skeleton-bar-chart';
import { splitLabelForChart } from './utils';

declare global {
  interface Window {
    resizedFinished?: string | number | NodeJS.Timeout;
  }
}

interface ChartsProps {
  showSkeleton?: boolean;
  tab?: TabOption;
  onClick?: (
    event: ChartEvent,
    elements: ActiveElement[],
    chart: ChartModel,
  ) => void;
}

export const BarChart = ({ tab, onClick, showSkeleton }: ChartsProps) => {
  const [chartData, setChartData] = useState<ChartData>({});
  const [chartOptions, setChartOptions] = useState({});

  const handleClick = (
    event: ChartEvent,
    elements: ActiveElement[],
    chart: ChartModel,
  ) => {
    if (typeof onClick === 'function') {
      onClick(event, elements, chart);
    }
  };

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary',
    );
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
    const data = {
      labels: (tab?.data || []).map((x) => x?.name),
      datasets: [
        {
          color: '#232A34',
          backgroundColor: '#E84F40',
          borderColor: '#DFE7F3',
          cursor: 'pointer',
          data: (tab?.data || []).map((x) => x?.count),
        },
      ],
    };
    const options: ChartOptions = {
      indexAxis: 'y',
      maintainAspectRatio: false,
      aspectRatio: 1.5,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          ticks: {
            color: 'textColorSecondary',
            callback: showSkeleton ? () => '' : undefined,
            font: {
              weight: 500,
            },
          },
          grid: {
            display: true,
          },
        },
        y: {
          ticks: {
            align: 'start',
            crossAlign: 'far',
            color: textColorSecondary,
            callback: (value) => {
              const sliceLables = (tab?.data || []).map((x) =>
                splitLabelForChart(x?.name, 45),
              );
              const str = (sliceLables || [])[value as number] || {} || '';
              return str;
            },
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
      onClick: handleClick,
      onHover: function (event: ChartEvent, elements: ActiveElement[]) {
        const target = event.native?.target;
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        (target as any)['style']['cursor'] = elements[0]
          ? 'pointer'
          : 'default';
      },
    };

    setChartData(data as ChartData);
    setChartOptions(options);

    const resizeHandler = () => {
      clearTimeout(window['resizedFinished']);
      window['resizedFinished'] = setTimeout(() => {
        setChartData({ ...data } as ChartData);
      }, 250);
    };
    window.addEventListener('resize', resizeHandler);
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [tab?.data]);

  return (
    <WithSkeletonBarChart showSkeleton={!!showSkeleton}>
      <Chart
        type="bar"
        data={!showSkeleton ? chartData : []}
        options={chartOptions}
      />
    </WithSkeletonBarChart>
  );
};
