import { TimeRangeValue } from 'components/time-range/models';
import { createContext, useContext } from 'react';
import { SummaryCluster } from '../shared/models';
import { Nullable } from 'primereact/ts-helpers';
export interface CheckSummaryState {
  clusters: Record<string, SummaryCluster>;
  timeRange: { value?: TimeRangeValue; from?: string; to?: string };
}

export interface CheckSummaryContextType {
  clusters?: Record<string, SummaryCluster>;
  date: Nullable<Date>;
  setDate: React.Dispatch<React.SetStateAction<Nullable<Date>>>;
  timeRange: { value: TimeRangeValue; from?: string; to?: string };
  setClusters: React.Dispatch<
    React.SetStateAction<Record<string, SummaryCluster> | undefined>
  >;
  getCluster: (id: string) => SummaryCluster | undefined;
  setTimeRange: React.Dispatch<
    React.SetStateAction<{
      value: TimeRangeValue;
      from?: string | undefined;
      to?: string | undefined;
    }>
  >;
}

export const CheckSummaryContext = createContext<
  CheckSummaryContextType | undefined
>(undefined);

export const useCheckSummary = () => {
  const context = useContext(CheckSummaryContext);
  if (!context) {
    throw new Error(
      'useCheckSummary must be used within a CheckSummaryProvider',
    );
  }
  return context;
};
