import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { Icon } from 'components/icon';
import { Skeleton } from 'primereact/skeleton';

export interface TileProps {
  title: React.ReactNode;
  className?: string;
  classes?: {
    count?: string;
    title?: string;
    context?: string;
  };
  titlePosition?: 'top' | 'bottom';
  showSkeleton?: boolean;
  textColor?: 'green' | 'red' | 'grey' | 'orange';
  count: number;
  icon?: string;
  iconSize?: 'large' | 'small';
  onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const Tile = ({
  title,
  className,
  classes,
  showSkeleton,
  textColor,
  titlePosition = 'top',
  iconSize = 'large',
  icon,
  count,
  onClick,
}: React.PropsWithChildren<TileProps>) => {
  return (
    <div
      onClick={onClick}
      className={cx(styles.tileLayout, styles.tileStyle, className)}
    >
      <div className={cx(styles.context, classes?.context)}>
        {titlePosition === 'top' && (
          <h4
            className={cx(
              styles.titleLayout,
              styles.titleStyle,
              classes?.title,
            )}
          >
            {showSkeleton ? <Skeleton width="6rem" height="1rem" /> : title}
          </h4>
        )}
        <div
          className={cx(
            styles.count,
            styles.countStyle,
            styles[textColor as string],
            classes?.count,
          )}
        >
          {showSkeleton ? <Skeleton height="2.5rem" /> : count}
        </div>
        {titlePosition === 'bottom' && (
          <h4
            className={cx(
              styles.titleLayout,
              styles.titleWithIcon,
              styles.titleStyle,
              classes?.title,
            )}
          >
            {showSkeleton ? (
              <Skeleton width="6rem" height="1rem" />
            ) : (
              <>
                {icon && iconSize === 'small' && (
                  <Icon
                    className={cx(styles.icon, styles[textColor as string])}
                    name={icon}
                    size={'1.5rem'}
                  />
                )}
                {title}
              </>
            )}
          </h4>
        )}
      </div>
      {icon && iconSize === 'large' && (
        <div className={cx(styles.iconBoxLayout, styles.iconBoxStyle)}>
          <Icon
            className={cx(styles.icon, styles[textColor as string])}
            name={icon}
            size={'2.5rem'}
          />
        </div>
      )}
    </div>
  );
};
