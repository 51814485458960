import { TimeRangeValue } from 'components/time-range/models';
import React, { useState } from 'react';
import { SummaryCluster } from '../shared/models';
import { CheckSummaryContext, CheckSummaryContextType } from './';
import { Nullable } from 'primereact/ts-helpers';

interface CheckSummaryProviderProps {}

export const CheckSummaryProvider: React.FC<
  React.PropsWithChildren<CheckSummaryProviderProps>
> = ({ children }) => {
  const [clusters, setClusters] = useState<Record<string, SummaryCluster>>();
  const [date, setDate] = useState<Nullable<Date>>(new Date());
  const [timeRange, setTimeRange] = useState<{
    value: TimeRangeValue;
    from?: string;
    to?: string;
  }>({ value: 'last_5_days' });

  const getCluster = (id: string) => {
    return (clusters || {})[id];
  };

  const value: CheckSummaryContextType = {
    clusters,
    timeRange,
    date,
    setDate,
    setClusters,
    setTimeRange,
    getCluster,
  };

  return (
    <CheckSummaryContext.Provider value={value}>
      {children}
    </CheckSummaryContext.Provider>
  );
};
