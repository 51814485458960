import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import styles from '../styles.module.scss';
import { Dropdown } from 'primereact/dropdown';
import { clusterViews } from '../shared/constants';
import { useCurrentCluster } from './context';
import {
  VirtualScroller,
  VirtualScrollerTemplateOptions,
} from 'primereact/virtualscroller';
import { Search } from 'components/search';
import { ProgressSpinner } from 'primereact/progressspinner';
import { LoadingDots } from 'components';
import { FilterMeta, FilterTags } from 'components/filter-tags';

export interface Data<T> {
  id: string;
  list: T[];
  loading: boolean;
  itemSize: number;
  header: React.ReactNode;
  searchFields: (keyof T)[];
  itemTemplate:
    | React.ReactNode
    | ((item: T, options: VirtualScrollerTemplateOptions) => React.ReactNode);
}
interface DataListViewProps<T> {
  data: Data<T>;
  searchValue: string;
  filters: FilterMeta;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  onClear: (key: keyof T, value: string) => void;
  onClearAll: () => void;
}

export function DataListView<T>({
  data,
  filters,
  searchValue,
  setSearchValue,
  onClearAll,
  onClear,
}: DataListViewProps<T>) {
  const { view, setView } = useCurrentCluster();
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setView('checks');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredDataList = data?.list.filter((item) => {
    return Object.entries(filters).every(([field, filter]) => {
      const itemValue = (item || {})[field];
      const filterValue = filter?.value;

      if (Array.isArray(filterValue)) {
        if (Array.isArray(itemValue)) {
          return filterValue
            .map(String)
            .some((val) =>
              ((itemValue || []) as string[]).map(String).includes(val),
            );
        }
        return filterValue.map(String).includes(String(itemValue));
      } else if (filterValue) {
        if (Array.isArray(itemValue)) {
          return ((itemValue || []) as string[])
            .map(String)
            .includes(String(filterValue));
        }
        return itemValue === String(filterValue);
      }
      return true;
    });
  });

  useEffect(() => {
    setVisible(false);
    const timer = setTimeout(() => {
      setVisible(true);
    }, 1);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id, data?.loading, filters]);

  return (
    <div className={cx(styles.dataListView)}>
      <div className={cx(styles.header)}>
        <h4 className={cx(styles.title)}>Cluster Details:</h4>
      </div>
      <div className={cx(styles.viewBy)}>
        <Dropdown
          value={view}
          onChange={(e) => {
            onClearAll();
            setSearchValue('');
            setView(e.value);
          }}
          options={clusterViews}
          optionLabel="label"
          placeholder="Select..."
          className={cx(styles.sortByFilter)}
        />
      </div>
      {Object.keys(filters || {})?.length > 0 && (
        <div className={cx(styles.headerFilter)}>
          <FilterTags
            titles={{
              status: 'Status',
              title: 'Check',
              priority: 'Priority',
              severity: 'Severity',
            }}
            onClear={onClear}
            filters={filters || {}}
          />
        </div>
      )}
      <Search
        value={searchValue}
        className={styles.withSearch}
        data={filteredDataList}
        searchFields={data?.searchFields}
        resultsComponent={(res, value) => {
          const showNoData =
            !data?.loading &&
            filteredDataList?.length === 0 &&
            res.length === 0;

          return (
            <div className={styles.viewListBox}>
              <div className="absolute">
                {visible && (
                  <>
                    <div className={styles.headerItems}>{data.header}</div>
                    <VirtualScroller
                      id={data?.id}
                      items={res}
                      loading={data?.loading}
                      showLoader
                      loadingIcon=<div className={styles.loading}>
                        <ProgressSpinner
                          style={{
                            width: '1.5rem',
                            height: '1.5rem',
                            margin: 0,
                          }}
                        />
                        Please wait <LoadingDots />
                      </div>
                      itemSize={data.itemSize || 55}
                      itemTemplate={data.itemTemplate}
                      autoSize
                      className={cx(styles.list)}
                      style={{
                        height: '100%',
                        display: showNoData ? 'none' : 'flex',
                      }}
                    />
                  </>
                )}
                {showNoData && (
                  <div className={cx(styles.loading, styles.empty)}>
                    Data is not currently available
                  </div>
                )}
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}
