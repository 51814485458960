import { DialogProps } from 'primereact/dialog';
import { createContext, useContext, ReactNode } from 'react';

export interface DialogState {
  header?: React.ReactNode | ((props: DialogProps) => React.ReactNode);
  footer?: React.ReactNode | ((props: DialogProps) => React.ReactNode);
  content?: ReactNode;
}

export interface DialogContextType {
  openDialog: (state: DialogState) => void;
  closeDialog: () => void;
}

export const DialogContext = createContext<DialogContextType | undefined>(
  undefined,
);

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};
