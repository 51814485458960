import {
  DataTable,
  DataTableFilterMeta,
  DataTableRowClickEvent,
  DataTableRowDataArray,
  DataTableStateEvent,
  DataTableValueArray,
  SortOrder,
} from 'primereact/datatable';
import {
  Column,
  ColumnBodyOptions,
  ColumnProps as PrimeColumnProps,
} from 'primereact/column';
import React from 'react';
import { Icon } from 'components/icon';
import { columWithFilter, getFilterItems } from './utils';
import { Skeleton } from 'primereact/skeleton';
import styles from './styles.module.scss';

export interface DataGridColumn<T> extends Omit<PrimeColumnProps, 'field'> {
  field?: keyof T;
  sortOptionsInFilter?: (a: T[keyof T], b: T[keyof T]) => number;
}

export interface ColumnProps<T> {
  filter?: boolean;
  options: DataGridColumn<T>;
}

export type DataGridColumns<T> = ColumnProps<T>[];

export interface DataGridProps<T> {
  showSkeleton?: boolean;
  sortField?: keyof T;
  sortOrder?: SortOrder;
  skeletonTemplate?:
    | React.ReactNode
    | ((data: T, options: ColumnBodyOptions) => React.ReactNode);
  filters?: DataTableFilterMeta;
  onValueChange?: (
    filteredData: DataTableRowDataArray<DataTableValueArray[]>,
  ) => void;
  onRowClick?(event: DataTableRowClickEvent & { data: T }): void;
  onFilter?(event: DataTableStateEvent): void;
  onSort?(event: DataTableStateEvent): void;
  data: T[];
  rows?: number;
  columns: ColumnProps<T>[];
}

export function DataGrid<T>({
  showSkeleton,
  skeletonTemplate,
  data,
  rows,
  sortField,
  sortOrder,
  onFilter,
  onSort,
  onRowClick,
  onValueChange,
  filters,
  columns,
}: DataGridProps<T>) {
  const onFilterHandler = (event: DataTableStateEvent) => {
    if (typeof onFilter === 'function') {
      onFilter(event);
    }
  };

  const onValueChangeHandler = (
    filteredData: DataTableRowDataArray<DataTableValueArray[]>,
  ) => {
    if (typeof onValueChange === 'function' && !showSkeleton) {
      onValueChange(filteredData);
    }
  };

  const isClickedRow = !showSkeleton && typeof onRowClick === 'function';
  const propsClikedRow = isClickedRow
    ? {
        onRowClick,
      }
    : {};

  return (
    <DataTable
      onValueChange={onValueChangeHandler}
      value={
        (showSkeleton
          ? Array.from({ length: 7 }, (_, i) => i)
          : data) as DataTableValueArray[]
      }
      resizableColumns
      paginator
      paginatorTemplate={''}
      sortField={sortField as string}
      sortOrder={sortOrder}
      rows={rows}
      pt={{
        bodyRow: {
          className: isClickedRow ? styles.clikedRow : '',
          style: {
            cursor: isClickedRow ? 'pointer' : 'default',
          },
        },
      }}
      {...propsClikedRow}
      onFilter={onFilterHandler}
      onSort={onSort}
      width={'100%'}
      filterDisplay="menu"
      stripedRows
      sortIcon={(d) => {
        return d.sorted ? (
          d.sortOrder && d.sortOrder < 0 ? (
            <Icon size={'1.5rem'} name="sorting_down" />
          ) : (
            <Icon size={'1.5rem'} name="sorting_up" />
          )
        ) : (
          <Icon size={'1.5rem'} name="sorting" />
        );
      }}
      filterIcon={() => <Icon size={'1.5rem'} name="filter" />}
      filters={showSkeleton ? {} : filters}
      removableSort
      tableStyle={{ minWidth: '50rem' }}
    >
      {(columns || []).map((x, i) => {
        const filterProps = x.filter
          ? columWithFilter(getFilterItems(data || [], x?.options), x?.options)
          : {};

        const skeletonProps: Partial<PrimeColumnProps> = {};
        if (showSkeleton) {
          skeletonProps.body = skeletonTemplate ?? <Skeleton />;
          skeletonProps.header = <Skeleton width="5rem" />;
        }

        return (
          <Column
            resizeable
            filter={x.filter}
            key={`column_${i}`}
            {...x?.options}
            {...filterProps}
            field={x?.options?.field as string}
            style={{
              minWidth: '12rem',
              whiteSpace: 'pre-wrap',
              ...x.options.style,
            }}
            showFilterMatchModes={false}
            {...skeletonProps}
          ></Column>
        );
      })}
    </DataTable>
  );
}
