import React, { useState } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { TabOption } from 'pages/reports/shared/models';
import { BarChart } from './bar-chart';
import { useDeepCompareEffect } from 'hooks/use-deep-compare-effect';
import { ActiveElement, Chart, ChartEvent } from 'chart.js';
import { Skeleton } from 'primereact/skeleton';

interface ChartsProps {
  showSkeleton?: boolean;
  title: React.ReactNode;
  tabs: TabOption[];
  onClickChart?: (
    event: ChartEvent,
    elements: ActiveElement[],
    tab: TabOption,
    chart: Chart,
  ) => void;
}

export const Charts = ({
  title,
  tabs,
  showSkeleton,
  onClickChart,
}: ChartsProps) => {
  const [activeTab, setActiveTab] = useState<TabOption>();

  useDeepCompareEffect(() => {
    setActiveTab((tabs || []).filter((x) => x.isActive)[0]);
  }, [tabs]);

  const handleChartClick = (
    event: ChartEvent,
    elements: ActiveElement[],
    chart: Chart,
  ) => {
    if (typeof onClickChart === 'function') {
      if (activeTab) {
        onClickChart(event, elements, activeTab, chart);
      }
    }
  };

  const handleOnClick =
    (x: TabOption) => (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      setActiveTab(x);
      if (typeof x?.onClick === 'function') {
        x?.onClick(x.id, x, e);
      }
    };

  return (
    <section className={cx(styles.charts, styles.chartsStyle)}>
      <header className={styles.header}>
        <h4 className={cx(styles.title, styles.titleStyle)}>
          {!showSkeleton ? title : <Skeleton width="5rem" />}
        </h4>
        {!showSkeleton && (
          <nav className={cx(styles.tabs)}>
            {(tabs || []).map((x, i) => (
              <span
                onClick={handleOnClick(x)}
                key={`chart-tab-item-${i}_${x.id}`}
                className={cx(styles.tabLayout, styles.tabStyle, {
                  [styles.active]: x.id === activeTab?.id,
                })}
              >
                {x.title}
              </span>
            ))}
          </nav>
        )}
      </header>
      <section className={cx(styles.body)}>
        <BarChart
          onClick={handleChartClick}
          tab={activeTab}
          showSkeleton={showSkeleton}
        />
      </section>
    </section>
  );
};
