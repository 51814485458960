export function slicer(arr: string[]): string[] {
  const result: string[] = [];
  if (arr?.length > 2) {
    const sArr = arr.slice(0, 2);
    sArr.forEach((string, index) => {
      if (index === sArr.length - 1) {
        result.push(string.slice(0, -3) + '...');
      } else {
        result.push(string);
      }
    });
  } else {
    result.push(...arr);
  }
  return result;
}

export function splitLabelForChart(label: string, lineLimit: number) {
  const words = label.split(' ');
  const lines = [];

  let line = '';
  let currentWordIdx = 0;

  while (currentWordIdx < words.length) {
    if (line.length + words[currentWordIdx].length < lineLimit) {
      line += `${words[currentWordIdx].trim()} `;
      currentWordIdx++;

      if (currentWordIdx === words.length) {
        lines.push(line.trim());
      }
    } else {
      if (line.length) {
        lines.push(line.trim());
        line = '';
      }

      if (words[currentWordIdx].length >= lineLimit) {
        lines.push(words[currentWordIdx].trim());
        currentWordIdx++;
      }
    }
  }
  return slicer(lines);
}
