export const FailedStatusColorBySeverity: Record<string, string> = {
  Critical: '#e84f40',
  High: '#f2c94c',
  Low: '#57ae60',
  Medium: '#2d9cdb',
  Severe: '#f2994a',
};

export const FailedStatusColorBy = [
  '#FF0000',
  '#FF4500',
  '#FFA500',
  '#FFD700',
  '#FFFF00',
];
