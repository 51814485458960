import { Chart } from 'primereact/chart';
import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';
import { ChartData, ChartOptions } from 'chart.js';

export interface StackedBarDataItem {
  label?: string;
  backgroundColor?: string;
  data: number[];
}

export interface StackedBarProps {
  labels: string[];
  title: React.ReactNode;
  headerTemplate?: (title: React.ReactNode) => React.ReactNode;
  data: StackedBarDataItem[];
  height?: string;
}

export const StackedBar = React.memo(
  ({ data: items, labels, title, headerTemplate, height }: StackedBarProps) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    const refContainer = useRef<HTMLDivElement | null>(null);
    const ref = useRef<Chart | null>(null);

    useEffect(() => {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColorSecondary = documentStyle.getPropertyValue(
        '--text-color-secondary',
      );
      const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

      const ctx = ref.current?.getCanvas()?.getContext('2d');

      const gradient = ctx?.createLinearGradient(0, 0, 0, 400);
      gradient?.addColorStop(0, 'rgba(36, 90, 142, 0.4)');
      gradient?.addColorStop(0.5616, 'rgba(36, 90, 142, 0.08)');
      gradient?.addColorStop(1, 'rgba(36, 90, 142, 0)');

      const data: ChartData = {
        labels,

        datasets: (items || []).map((x) => ({
          type: 'bar',
          ...x,
        })),
      };
      const options: ChartOptions = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            stacked: true,
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
      };

      setChartData(data);
      setChartOptions(options);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    const titleTemplate = <h4 className={cx(styles.title)}>{title}</h4>;

    return (
      <div className={cx(styles.stackedBar)}>
        <div className={cx(styles.header)}>
          {typeof headerTemplate === 'function'
            ? headerTemplate(titleTemplate)
            : titleTemplate}
        </div>
        <div style={{ minHeight: height }} className={styles.chartbody}>
          <div
            style={{ minHeight: height }}
            ref={refContainer}
            className="absolute"
          >
            {
              <Chart
                ref={ref}
                type="bar"
                data={chartData}
                options={chartOptions}
              />
            }
          </div>
        </div>
      </div>
    );
  },
);

StackedBar.displayName = 'StackedBar';
