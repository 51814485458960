import { TileProps } from 'components';
import { StackedBarDataItem } from 'components/stacked-bar';
import { severityOrder } from 'pages/reports/shared/configurations';
import { CheckEvolution } from 'shared/api/client';
import {
  DashboardTilesProps,
  DataItem,
  GroupedDataKey,
  SummaryCluster,
} from '../models';
import { FailedStatusColorBySeverity, FailedStatusColorBy } from '../cosntanst';

export const getDashboardDataByClusters = (
  clusters: Record<string, SummaryCluster>,
) => {
  const summary: DashboardTilesProps = {
    totalClusters: 0,
    unhealthyClusters: 0,
    healthyClusters: 0,
    reportsReceived: 0,
    reportsMissing: 0,
    reportsPartial: 0,
    groupedData: {} as Record<GroupedDataKey, number>,
  };
  Object.entries(clusters).forEach(([key, v]) => {
    summary.reportsReceived += v?.reportStatus === 'DONE' ? 1 : 0;
    summary.reportsMissing += v?.reportStatus === 'MISSING' ? 1 : 0;
    summary.reportsPartial += v?.reportStatus === 'ERROR' ? 1 : 0;

    // Aggregate groupedData
    for (const [dataKey, dataValue] of Object.entries(v?.groupedData || {})) {
      const groupedDataKey = dataKey as GroupedDataKey;
      if (!summary?.groupedData[groupedDataKey]) {
        summary.groupedData[groupedDataKey] = 0;
      }
      summary.groupedData[groupedDataKey] += dataValue;
    }
  });
  summary.totalClusters = Object.keys(clusters).length;
  Object.values(clusters).forEach((x) => {
    summary.healthyClusters += x?.isHealthy ? 1 : 0;
    summary.unhealthyClusters += !x?.isHealthy ? 1 : 0;
  });
  return summary;
};

export const getDashboardDataByCluster = (cluster: SummaryCluster) => {
  return {
    name: cluster?.name,
    groupedData: cluster?.groupedData,
  };
};

export const getChecksEvolutionChart = (
  res: Record<string, CheckEvolution>,
) => {
  const counts: Record<string, Record<string, number>> = {};
  const datasets: Record<string, StackedBarDataItem> = {};
  const labels: string[] = [];

  Object.entries(res || {}).forEach(([k, v]) => {
    labels.push(k);
    counts[k] = {};

    (v.clusters || []).forEach((b) => {
      Object.entries(b.counts || {}).forEach(([cK, cV]) => {
        counts[k] = {
          ...counts[k],
          [cK]: ((counts[k] || {})[cK] || 0) + (cV || 0),
        };
        if (!datasets[cK]) {
          datasets[cK] = {
            label: cK,
            data: [],
          };
        }
      });
    });
  });
  const data: StackedBarDataItem[] = [];
  Object.entries(datasets || {})
    .sort(([aK, aV], [bK, bV]) => {
      const severityIndexA = severityOrder.indexOf(aK || '');
      const severityIndexB = severityOrder.indexOf(bK || '');
      if (severityIndexA !== -1 && severityIndexB !== -1) {
        return severityIndexA - severityIndexB;
      } else if (severityIndexA !== -1) {
        return -1;
      } else if (severityIndexB !== -1) {
        return 1;
      } else {
        return aK.localeCompare(bK);
      }
    })
    .forEach(([kO, vO]) => {
      const { colors } = getFailedStatusColorsBy(datasets);
      data.push({
        ...vO,
        backgroundColor: colors[kO],
        data: Object.entries(counts || {}).map(([_, c]) => c[kO]),
      });
    });

  return {
    data,
    labels,
  };
};

export const getDashboardTiles = ({
  totalClusters,
  unhealthyClusters,
  healthyClusters,
  onClick,
}: DashboardTilesProps): TileProps[] => [
  {
    title: 'Total Clusters',
    count: totalClusters,
    onClick: (e) => {
      if (typeof onClick === 'function') {
        onClick('total');
      }
    },
  },
  {
    title: 'Healthy Clusters',
    textColor: 'green',
    count: healthyClusters,
    iconSize: 'small',
    icon: 'pass',
    onClick: (e) => {
      if (typeof onClick === 'function') {
        onClick('healthy');
      }
    },
  },
  {
    title: 'Unhealthy Clusters',
    textColor: 'red',
    count: unhealthyClusters,
    iconSize: 'small',
    icon: 'fail',
    onClick: (e) => {
      if (typeof onClick === 'function') {
        onClick('unHealthy');
      }
    },
  },
  // {
  //     title: 'Errors',
  //     textColor: 'orange',
  //     count: 131,
  //     iconSize: 'small',
  //     icon: 'error',
  //     // onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  // },
];

export const sortFailedStatus = (a: DataItem, b: DataItem) => {
  const severityIndexA = severityOrder.indexOf(a.name || '');
  const severityIndexB = severityOrder.indexOf(b.name || '');
  if (severityIndexA !== -1 && severityIndexB !== -1) {
    return severityIndexA - severityIndexB;
  } else if (severityIndexA !== -1) {
    return -1;
  } else if (severityIndexB !== -1) {
    return 1;
  } else {
    return a?.name?.localeCompare(b?.name);
  }
};

export function getFailedStatusColorsBy<T>(data: T): {
  isSeverity: boolean;
  colors: Record<string, string>;
} {
  const names = Object.keys(data || {}) || [];
  const isSeverity = names?.some((val) => severityOrder?.includes(val));

  const colors = isSeverity
    ? FailedStatusColorBySeverity
    : names
        .sort((a, b) => {
          if (typeof a === 'string' && typeof b === 'string') {
            return a.localeCompare(b);
          }
          return 0;
        })
        .reduce(
          (dict, value, index) => ({
            ...dict,
            [value]: FailedStatusColorBy[index],
          }),
          {} as Record<string, string>,
        );
  return {
    isSeverity,
    colors,
  };
}
