import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

export interface ReportStatusProps {
  type?: 'DONE' | 'ERROR' | 'NEW' | 'IN_PROGRESS';
}

export const ReportStatus = ({
  children,
  type,
}: React.PropsWithChildren<ReportStatusProps>) => {
  if (!type) {
    return <></>;
  }
  const status = (type || '')?.toLowerCase();
  return (
    <span className={cx(styles.reportStatus, styles[status])}>
      <span className={cx(styles.label, styles[status])}>{children}</span>
    </span>
  );
};
