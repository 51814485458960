/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListChecksResponse } from '../models/ListChecksResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ChecksService {
  /**
   * List Checks
   * @returns ListChecksResponse Successful Response
   * @throws ApiError
   */
  public static listChecksApiV1ChecksGet(): CancelablePromise<ListChecksResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/checks',
      errors: {
        400: `Bad request`,
        404: `Not found`,
        500: `Internal error`,
      },
    });
  }
}
