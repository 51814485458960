/* eslint no-case-declarations: "off"*/
import { TimeRangeValue } from 'components/time-range/models';
import { getDate } from 'components/utils';

export * from './normalized-data.utils';

export function isNullOrUndefined<T>(value: T) {
  return value === null || value === undefined;
}

export const getAssetsPath = (fileName: string, extension = 'svg') =>
  `/assets/${fileName}.${extension}`;

export function isEqual<T>(value: T, other: T): boolean {
  // Check for simple equality
  if (value === other) {
    return true;
  }

  // Check for null or undefined
  if (value == null || other == null) {
    return false;
  }

  // Check for type equality
  if (typeof value !== typeof other) {
    return false;
  }

  // Check for object or array
  if (typeof value === 'object') {
    const valueKeys = Object.keys(value);
    const otherKeys = Object.keys(other);

    // Check for equal number of keys
    if (valueKeys.length !== otherKeys.length) {
      return false;
    }

    // Compare each key and value
    for (const key of valueKeys) {
      if (
        !otherKeys.includes(key) ||
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        !isEqual((value as any)[key], (other as any)[key])
      ) {
        return false;
      }
    }

    return true;
  }

  // Values are not objects or arrays and have already been checked for equality
  return false;
}

export function toCapitalize(inputString: string): string {
  if (inputString?.length === 0) {
    return inputString;
  }

  const words: string[] = inputString?.toLowerCase().split(' ');

  const capitalizedWords: string[] = (words || [])?.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  const capitalizedString: string = capitalizedWords.join(' ');

  return capitalizedString;
}

export const isEmptyString = (s: string | null | undefined) => {
  return typeof s === 'string' && s?.trim() === '';
};

export function isEmpty<T>(value: T) {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value === 'object') {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else if (value instanceof Map || value instanceof Set) {
      return value.size === 0;
    } else {
      return Object.keys(value).length === 0;
    }
  }

  return value === '';
}

export function normalizeBoolean(
  value: string | boolean | undefined | null,
): boolean {
  if (typeof value === 'boolean') {
    return value;
  } else if (typeof value === 'string') {
    return value?.toLowerCase() === 'true';
  } else {
    throw new Error(
      'Unsupported value type. Only string and boolean are supported.',
    );
  }
}

export function formatNumber(num: number) {
  if (Number.isInteger(num)) {
    return num?.toString();
  } else {
    return num?.toFixed(2);
  }
}

export function getDateRange(
  option: TimeRangeValue,
  customStartDate?: string,
  customEndDate?: string,
): { startDate: string; endDate: string } | null {
  const today = new Date();
  let startDate: string;
  let endDate: string = getDate(today?.toJSON()); // Default end date is today

  switch (option) {
    case 'today':
      startDate = endDate; // If today, start and end dates are the same
      break;

    case 'last_5_days':
      const sDateL5D = new Date(today);
      sDateL5D.setDate(today.getDate() - 5);
      startDate = getDate(sDateL5D.toJSON());
      break;

    case 'last_week':
      const sDateLW = new Date(today);
      sDateLW.setDate(today.getDate() - 7);
      startDate = getDate(sDateLW.toJSON());
      break;

    case 'last_month':
      const sDateLM = new Date(today);
      sDateLM.setMonth(today.getMonth() - 1);
      startDate = getDate(sDateLM.toJSON());
      break;

    case 'last_year':
      const sDateLY = new Date(today);
      sDateLY.setFullYear(today.getFullYear() - 1);
      startDate = getDate(sDateLY.toJSON());
      break;

    case 'custom':
      if (!customStartDate || !customEndDate) {
        console.warn("Custom dates must be provided for 'custom' option");
        return null;
      }
      startDate = customStartDate;
      endDate = customEndDate;
      break;

    default:
      console.error('Invalid option');
      return null;
  }

  return { startDate, endDate };
}
