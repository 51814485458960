import {
  defaultFilters,
  defaultSortingReportColumns,
} from 'pages/reports/shared/configurations';
import { ReportModel } from 'pages/reports/shared/models';
import { DataTableFilterMeta } from 'primereact/datatable';
import React, { ReactNode, useState } from 'react';
import {
  CurrentReportContext,
  CurrentReportContextType,
  CurrentReportState,
  SortByReportTable,
} from './';

interface CurrentReportProviderProps {
  children: ReactNode;
}

export const CurrentReportProvider: React.FC<CurrentReportProviderProps> = ({
  children,
}) => {
  const [currentReportState, setCurrentReportState] =
    useState<CurrentReportState>({
      loadedReports: {},
    });

  const [filteredCounts, setFilteredCountsState] = useState<{
    [key: string]: number;
  }>({});

  const setLoadedReport = (data: ReportModel) => {
    setCurrentReportState((prev) => {
      if (data?.id) {
        return {
          ...prev,
          loadedReports: {
            ...(prev?.loadedReports || {}),
            [data.id]: {
              ...(prev?.loadedReports || {})[data?.id],
              data,
              filters: {
                ...((prev?.loadedReports || {})[data?.id]?.filters || {
                  ...defaultFilters,
                  result: { value: ['Fail'], matchMode: 'in' }, // Note: set default by failed items
                }),
              },
              sortBy: {
                ...((prev?.loadedReports || {})[data?.id]?.sortBy ||
                  defaultSortingReportColumns),
              },
            },
          },
        };
      }
      return prev;
    });
    setFilteredCountsState((prev) => {
      if (data?.id) {
        return {
          ...prev,
          [data?.id]: Object.keys(data?.items || {})?.length || 0,
        };
      }
      return prev;
    });
  };

  const updateReport = (
    key: string,
    dataReport: ReportModel | ((prev: ReportModel) => ReportModel),
  ) => {
    setCurrentReportState((prev) => {
      const newReportState =
        typeof dataReport === 'function'
          ? dataReport(((prev?.loadedReports || {})[key] || {})?.data || {})
          : dataReport;
      return {
        ...prev,
        loadedReports: {
          ...(prev?.loadedReports || {}),
          [key]: {
            ...(prev?.loadedReports || {})[key],
            data: {
              ...((prev?.loadedReports || {})[key]?.data || {}),
              ...newReportState,
            },
          },
        },
      };
    });
  };

  const setFilter = (
    reportId: string,
    data:
      | DataTableFilterMeta
      | ((prev: DataTableFilterMeta) => DataTableFilterMeta),
  ) => {
    setCurrentReportState((prev) => {
      return {
        ...prev,
        loadedReports: {
          ...(prev?.loadedReports || {}),
          [reportId]: {
            ...(prev?.loadedReports || {})[reportId],
            filters: {
              ...(prev?.loadedReports || {})[reportId]?.filters,
              ...(typeof data === 'function'
                ? data((prev?.loadedReports || {})[reportId]?.filters || {})
                : data),
            },
          },
        },
      };
    });
  };

  const setSort = (
    reportId: string,
    data: SortByReportTable | ((prev: SortByReportTable) => SortByReportTable),
  ) => {
    setCurrentReportState((prev) => {
      return {
        ...prev,
        loadedReports: {
          ...(prev?.loadedReports || {}),
          [reportId]: {
            ...(prev?.loadedReports || {})[reportId],
            sortBy: {
              ...(prev?.loadedReports || {})[reportId]?.sortBy,
              ...(typeof data === 'function'
                ? data((prev?.loadedReports || {})[reportId]?.sortBy || {})
                : data),
            },
          },
        },
      };
    });
  };

  const setFilteredCount = (reportId: string, count: number) => {
    setFilteredCountsState((prev) => {
      return {
        ...(prev || {}),
        [reportId]: count || 0,
      };
    });
  };

  const getReport = (id: string | undefined) => {
    if (id) {
      return (currentReportState?.loadedReports || {})[id];
    }
    return null;
  };

  const value: CurrentReportContextType = {
    loadedReports: currentReportState?.loadedReports,
    filteredCounts,
    getReport,
    updateReport,
    setFilter,
    setSort,
    setFilteredCount,
    setLoadedReport,
  };

  return (
    <CurrentReportContext.Provider value={value}>
      {children}
    </CurrentReportContext.Provider>
  );
};
